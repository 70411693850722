import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { bookingActions, setActions } from "../../../../actions";
import { bookingService } from "../../../../services";
import dateFormat from "dateformat";
import moment from "moment";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import {DatePicker} from "reactstrap-date-picker";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

const head = [
    { title: "Id", dataIndex: "Id"},
    { title: "Hospital Name", dataIndex: "Hospital Name"},
    { title: "Dispatch Date", dataIndex: "Dispatch Date"},
    { title: "Delivery Time", dataIndex: "Delivery Time" },
    { title: "Operation Date", dataIndex: "Operation Date" },
    { title: "Slot", dataIndex: "Slot" },
    { title: "Notes", dataIndex: "Notes" },
    { title: "Kit", dataIndex: "Kit" }
];

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            newBookingList: [],
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal:false,
            kitSetData:[],
            kitDetailsClickFlag:0,
            kitDetails:[],
            cancelModal:false, 
            cancelId:0,
            dateModal:false,
            bookingId:0,
            bokkingDate:[{date_delivery_date:"",date_collection_date:"",date_operation_date:""}],
            dateSubmit:0,
            searchOperationFromDate:"",
            searchOperationToDate:"",
            dataXlsx: [],
            setList: [],
            setStatus: [],
            itemUsedModal: false,
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.cancelModalClose = this.cancelModalClose.bind(this);
        this.dateModalClose = this.dateModalClose.bind(this);
        this.dateModalOpen = this.dateModalOpen.bind(this);
        this.arrangeBookingList = this.arrangeBookingList.bind(this);
        this.itemUsedModalOpen = this.itemUsedModalOpen.bind(this);
        this.itemUsedModalClose = this.itemUsedModalClose.bind(this);
        this.updateSetStatus = this.updateSetStatus.bind(this);
        this.itemUsedUpdate = this.itemUsedUpdate.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(bookingActions.getList({type:["loan"],status:["booked","dispatch","return"],from_date: this.state.searchOperationFromDate, to_date: this.state.searchOperationToDate, search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
    }

    handleSearchOperationFromDate(val){
        this.setState({
            searchOperationFromDate: moment(val).format("YYYY-MM-DD"),
            activePage:1
        },()=>{this.getList();});
        // console.log(moment(val).format("YYYY-MM-DD"));
    }

    handleSearchOperationToDate(val){
        this.setState({
            searchOperationToDate: moment(val).format("YYYY-MM-DD"),
            activePage:1
        },()=>{this.getList();});
    }

    handleSearchOperationDate() {
        this.setState({
            searchOperationFromDate: "",
            searchOperationToDate: "",
            activePage:1
        },()=>{this.getList();});
    }

    kitSetDetailModalClose(){
        this.setState({
            kitSetDetailModal :false,
            kitSetData:[]
        });
    }

    itemUsedModalOpen(id){
        bookingService.getSetList(id).then((res) => {
            this.setState({
                setList: res.data[0].bookingKitSetId,
                itemUsedModal: true
            });
        });
    }

    itemUsedModalClose(){
        this.setState({
            itemUsedModal: false
        });
    }

    updateSetStatus(val, index){
        let setList = this.state.setList;
        setList[index].item_in_used = val;
        this.setState({setList:setList});
    }

    itemUsedUpdate() {
        let data=[];
        let setListData=this.state.setList;

        setListData.map((item)=>{
            data.push({ id:item._id, item_in_used:item.item_in_used });
        });

        bookingService.updateItemUsedStatus({data:data}).then(() => {
            this.itemUsedModalClose();
        });
    }

    showKitDetails(kitDetails){

        if(this.state.kitDetailsClickFlag==1)
        {
            this.setState({
                kitDetails:kitDetails,
                kitSetDetailModal : true,
                kitDetailsClickFlag:0
            }); 
        }
      
    }

    handleKitDetails(id){

        this.setState({
            kitDetailsClickFlag:1
        });
       
        this.props.dispatch(setActions.getById(id));

    }

    cancelModalOpen(id){

        this.setState({
            cancelModal:true,
            cancelId:id
        });
    }

    cancelModalClose(){

        this.setState({
            cancelModal:false
        });

    }

    handleCancle(){
        this.props.dispatch(bookingActions.updateStatus({id: this.state.cancelId,status:"cancel"}));
    }

    dateModalOpen(item){

        this.setState({
            dateModal:true,
            bookingId:item.bookings._id,
            dateSubmit:0,
            bokkingDate:
            {id:item.bookings._id,log_status:"updated",log_by_id:this.state.user.data._id,delivery_date:dateFormat(item.bookings.delivery_date,"yyyy-mm-dd"),collection_date:dateFormat(item.bookings.collection_date,"yyyy-mm-dd"),operation_date:dateFormat(item.bookings.operation_date,"yyyy-mm-dd"),}
        });

     

       
    }

    dateModalClose(){
        this.setState({
            dateModal:false
        });
    }

    handleCollectionDate(val){
        const {bokkingDate} = this.state;
        bokkingDate.collection_date=val;
        this.setState({
            bokkingDate:bokkingDate
        });

    }

    handleOprationDate(val){
        const {bokkingDate} = this.state;
        bokkingDate.operation_date=val;
        this.setState({
            bokkingDate:bokkingDate
        });

    }   

    handleUpdateDate(){
        this.setState({
            dateSubmit:1
        });
        this.props.dispatch(bookingActions.updateBookingDates(this.state.bokkingDate));
    }

    arrangeBookingList(bookingList) {
        var newBookingList = [];
        var prevDate = "";
        var prevIndex = 0;
        var todayIndex = "";
        
        bookingList.map((bookingItem, index) => {
            var currDate = bookingItem.bookings.operation_date;

            if(currDate !== prevDate) {
                if(index != 0) {
                    prevIndex = prevIndex + 1;
                }
                prevDate = bookingItem.bookings.operation_date;
                newBookingList[prevIndex] = [];
            }
            
            var todayDate = moment().format("DD/MM/YYYY");
            
            if( moment(currDate).format("DD/MM/YYYY") == todayDate) {
                todayIndex = prevIndex;
            }      

            newBookingList[prevIndex].push(bookingItem);            
        });

        if(todayIndex !== "") {
            var todayBooking = newBookingList[todayIndex];
            newBookingList.splice(todayIndex, 1);
            newBookingList.unshift(todayBooking);
        }

        this.setState({
            newBookingList: newBookingList
        });

        //console.log(newBookingList);
    }

    capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    showXlsData(data){

        var dataXlsx = [];
        var todayIndex = [];

        data.map((item, index) => {

            var kitDataArray = [];

            item.bookingKitSetId.map((kitdata) => {
                if(!kitDataArray.includes(kitdata.kitData[0].name)) {
                    kitDataArray.push(kitdata.kitData[0].name);
                } 
            });

            dataXlsx.push({
                "Id": item.bookings.booking_id,
                "Hospital Name": item.hospitalData[0].name,
                "Dispatch Date": moment(item.bookings.delivery_date).format("ddd, MMMM Do, YYYY"),
                "Operation Date": moment(item.bookings.operation_date).format("ddd, MMMM Do, YYYY"),
                "Slot": item.bookings.operation_date_slot,
                "Delivery Time": item.bookings.delivery_date_slot,
                "Notes": item.bookings.additional_notes,
                "Kit": kitDataArray.join()
            });

            var todayDate = moment().format("DD/MM/YYYY");
            
            if( moment(item.bookings.operation_date).format("DD/MM/YYYY") == todayDate) {
                todayIndex.push(index);
            }
        });

        if(todayIndex.length !== 0) {
            for(var i = 0; i < todayIndex.length; i++) {
                var todayBooking = dataXlsx[todayIndex[i]];
                dataXlsx.splice(todayIndex[i], 1);
                dataXlsx.unshift(todayBooking);
            }
        }

        this.setState({
            dataXlsx: dataXlsx
        });
    }
   
    componentDidMount(){
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(nextProps.refreshList==true  || nextProps.redirect==true)
        {
           
            this.setState({ 
                activePage: 1,
                cancelModal:false,
                dateModal:false
            },()=>{this.getList();}); 
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount!=undefined && nextProps.totalItemsCount!= "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }
       
        if( nextProps.bookingList != undefined) {
          
            this.arrangeBookingList(nextProps.bookingList);
        }

        if( nextProps.bookingList != undefined) {
            this.showXlsData(nextProps.bookingList);
        }

        if(nextProps.editSet == true ) {
            this.showKitDetails(nextProps.set);
        }
        
        
    }

    render() {
        const {loading, error } = this.props;
        const { bokkingDate } = this.state;
      
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator="-";
        const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
        // console.log(todayDate);
        return (
            <div>
                <Navbar activePage="bookings"/>
                <div className="container-fluid">

                    <div className="row pt-4 align-items-center">
                        <div className="col-lg-3 col-md-12">
                            <ul className="sub-menu">
                                <li><Link to="/admin/bookings" className="active">Open</Link></li>
                                <li><Link to="/admin/bookings/consignment">Consignment</Link></li>
                                <li><Link to="/admin/bookings/close">Closed</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-6">
                            <form className="form-search">
                                <Input 
                                    className="form-control mr-sm-2" 
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />             
                            </form>
                        </div>  
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="label_adjust">
                                <label className="small mb-0">Operation Date </label>
                                <span className="small ml-4 btn-clear"><a href="javascript:;" onClick={()=>this.handleSearchOperationDate()}>Clear</a></span>
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        {/* <Input 
                                            className="form-control form-control-sm mr-sm-2 rounded-left" 
                                            type="date"
                                            placeholder="From"
                                            value={this.state.searchOperationFromDate}
                                            onChange={(e) => this.handleSearchOperationFromDate(e.target.value)}
                                        /> */}
                                        <DatePicker
                                            showClearButton = {false}
                                            placeholder = "From"
                                            value   = {this.state.searchOperationFromDate} 
                                            //onChange= {(v,f) => handleChange(v, f)}
                                            onChange = {(e) => this.handleSearchOperationFromDate(e)}
                                        />
                                    </div>
                                    <div className="col-6">
                                        {/* <Input 
                                            className="form-control form-control-sm mr-sm-2 rounded-right" 
                                            type="date"
                                            placeholder="To"
                                            value={this.state.searchOperationToDate}
                                            //onChange={(e) => this.handleSearchOperationToDate(e.target.value)}
                                            onchange={() => alert("Hello")}
                                        /> */}
                                        <DatePicker
                                            showClearButton = {false}
                                            placeholder = "To"
                                            value   = {this.state.searchOperationToDate} 
                                            //onChange= {(v,f) => handleChange(v, f)}
                                            onChange = {(e) => this.handleSearchOperationToDate(e)}
                                        />
                                    </div> 
                                </div>
                            </div>            
                        </div>
                        <div className="col-lg-3 col-md-3 text-right">
                            <Link className="btn btn-primary add_option" to="/admin/booking/add" ><i className="fa fa-plus"></i> <span>Add New</span></Link>
                            <ExportSheet header = {head} fileName = {"bookings"} dataSource = {this.state.dataXlsx} xlsx = {XLSX} >
                                <button className="btn btn-default add_print ml-3">Print</button>
                            </ExportSheet>
                        </div>
                    </div>

                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200">Hospital Name</th>
                                    <th scope="col" className="mobilehide">Contact Person</th>             
                                    <th scope="col" className="mobilehide">Sets</th>
                                    <th scope="col">Operation Name</th>            
                                    <th scope="col">Important Dates</th>
                                    <th scope="col">Booked By</th>                   
                                    <th scope="col" className="text-right" width="185">Actions</th>                
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.newBookingList && this.state.newBookingList.map((item) => (
                                    item.map((innerItem, index) => (
                                        <React.Fragment key={innerItem.bookings._id} >
                                            {
                                                index == 0 && 
                                                <tr className="data_date" key={innerItem.bookings._id}>
                                                    <th colSpan="7" className="text-center">
                                                        { moment(innerItem.bookings.operation_date).format("ddd, MMMM Do, YYYY")}
                                                    </th>
                                                </tr>
                                            }
                                            <tr>
                                                <td width="200" data-th="Hospital Name" className="mobilehide_heading">
                                                    <div className="">                   
                                                        <Link to={"/admin/booking/details/"+innerItem.bookings._id}>
                                                            <span><strong><span style={{"display": "block"}} className="theme-text">#{innerItem.bookings.booking_id}</span>
                                                                {innerItem.hospitalId.name}</strong></span><br />
                                                            { innerItem.bookingSurgeonData.length>0 &&
                                                                <React.Fragment>
                                                                    <span><small>Surgeon:</small> <strong>{innerItem.bookingSurgeonData[0].SurgeonDetail[0].first_name+" "+innerItem.bookingSurgeonData[0].SurgeonDetail[0].last_name}</strong></span><br />
                                                                </React.Fragment>    
                                                            }
                                                            <small className="mobilehide">Booked on {dateFormat(innerItem.bookings.createdAt, "mmmm dS, yyyy")}</small>                          
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td data-th="Contact Person" className="mobilehide">
                                                    <div className="info-left">
                                                        <strong>{innerItem.hospitalId.contact_person_name} </strong><br />
                                                        <small>Location: {innerItem.bookings.hospital_address} </small><br />
                                                        <small>Email: {innerItem.hospitalId.email}</small><br />
                                                        <small>Phone: {innerItem.hospitalId.contact_person_phone_number}</small>
                                                    </div>
                                                </td>
                                                <td data-th="Sets" className="mobilehide">
                                                    <small>
                                                        { innerItem.bookingKitSetId && innerItem.bookingKitSetId.map((kset,ind)=>(
                                                            <React.Fragment key={ind}>
                                                                <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div>
                                                            </React.Fragment>
                                                        ))}
                                                    
                                                    </small>                        
                                                </td>
                                                <td data-th="Operation Name">
                                                    { innerItem.bookings.operation_name === "Other" 
                                                        ?<span> {innerItem.bookings.other_opration} </span>
                                                        :<span> {innerItem.bookings.operation_name} </span>
                                                    }
                                                </td>
                                                <td data-th="Important Dates" className="mobilehide_heading">
                                                    <small>
                                                        
                                                        {innerItem.bookings.booking_type=="loan" &&
                                                            <div>
                                                                <span className="mobilehide">Delivery Date: <strong>{dateFormat(innerItem.bookings.delivery_date, "mmmm dS, yyyy")} {innerItem.bookings.delivery_date_slot}</strong><br /></span>
                                                                Operation Date: <strong>{dateFormat(innerItem.bookings.operation_date, "ddd, mmmm dS, yyyy")} {innerItem.bookings.operation_date_slot}</strong><br />
                                                            </div>
                                                        }
                                                        {innerItem.bookings.booking_type !="loan" &&
                                                            <div>
                                                                <span className="mobilehide">Delivery Date: <strong>{dateFormat(innerItem.bookings.delivery_date, "mmmm dS, yyyy")}</strong></span><br />
                                                            </div>
                                                        }
                                                        <span className="mobilehide">Collection Date: <strong>{dateFormat(innerItem.bookings.collection_date, "mmmm dS, yyyy")}</strong></span>

                                                        
                                                    </small>
                                                </td> 
                                                <td data-th="Booked By" className="mobilehide_heading">
                                                    {innerItem.booked_by_user.length > 0 ?
                                                        innerItem.booked_by_user[0].first_name+" "+innerItem.booked_by_user[0].last_name : "-"
                                                    }
                                                </td>
                                                <td className="text-right">
                                                    <div className="action-area dropdown">
                                                        <span  
                                                            className={"capsule " +(innerItem.bookings.status=="booked" ? "green-dim " : "red-dim ") +" mr-3"} 
                                                        >
                                                            { innerItem.bookings.status=="dispatch" && 
                                                                <div>Dispatched</div>
                                                            }
                                                            { innerItem.bookings.status!="dispatch" && 
                                                                this.capitalize(innerItem.bookings.status)
                                                            }
                                                        
                                                        </span>
                                                        <span><Link className="btn-link mr-2 desktophide" to={"/admin/booking/details/"+innerItem.bookings._id}>More Details</Link></span>
                                                        <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="dots">...</span>
                                                        </a>
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                            <Link className="dropdown-item mobilehide" to={"/admin/booking/details/"+innerItem.bookings._id}>More Details</Link>
                                                            { innerItem.bookings.status=="booked" &&  dateFormat(innerItem.bookings.delivery_date,"yyyy-mm-dd") == todayDate &&
                                                                <Link className="dropdown-item" to={"/admin/booking/details/"+innerItem.bookings._id}>Dispatch</Link>
                                                            }
                                                            { innerItem.bookings.status=="dispatch" &&  dateFormat(innerItem.bookings.return_date,"yyyy-mm-dd") == todayDate &&
                                                                <Link className="dropdown-item" to={"/admin/booking/details/"+innerItem.bookings._id}>Return</Link>
                                                            }
                                                            { innerItem.bookings.status=="dispatch" &&
                                                                <a href="javascript:;" onClick={()=>this.itemUsedModalOpen(innerItem.bookings._id)} className="dropdown-item" >Item Used</a>
                                                            }
                                                            { innerItem.bookings.status=="dispatch" && 
                                                                <a href="javascript:;" onClick={()=>this.dateModalOpen(innerItem)} className="dropdown-item" >Change Dates</a>
                                                            }
                                                            
                                                            { innerItem.bookings.status === "booked"  &&
                                                                <Link className="dropdown-item" to={"/admin/booking/edit/"+innerItem.bookings._id}>Edit</Link>
                                                            }
                                                            { innerItem.bookings.status=="booked" && 
                                                                <a href="javascript:;" onClick={()=>this.cancelModalOpen(innerItem.bookings._id)} className="dropdown-item" >Delete</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                ))}
                                { this.state.newBookingList && Object.keys(this.state.newBookingList).length == 0 && loading == false && 
                                    <tr className="text-center">
                                        <td colSpan="7">No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>                    
                </div>

                {this.state.newBookingList && this.state.newBookingList.length >0 &&
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Previous"
                    />
                }

             

                <Modal size="md" isOpen={this.state.cancelModal} toggle={() => this.cancelModalClose()}>
                    <ModalHeader className="" toggle={() => this.cancelModalClose()}>
                        Delete Booking
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this booking?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.cancelModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false &&
                                <button className="btn btn-primary" onClick={() =>this.handleCancle()}>Ok</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}

                <Modal size="ml" isOpen={this.state.dispatchModal} toggle={() => this.dispatchModalClose()}>
                    <ModalHeader className="" toggle={() => this.dispatchModalClose()}>
                        Dispatch
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to dispatch this booking?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.dispatchModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={() =>this.handleDispatch()}>Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Cancle Modal*/}

                <Modal size="lg" isOpen={this.state.kitSetDetailModal} toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader className="" toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules && this.state.kitDetails.setProductModules.map((product,ind)=>(
                                    <tr key={ind}>
                                        <td>{product.productID.code}</td>
                                        <td>{product.productID.name}</td>
                                        <td>{product.quantity}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary" onClick={() =>this.kitSetDetailModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Set Details Modal */}

                <Modal size="ml" isOpen={this.state.dateModal} toggle={() => this.dateModalClose()}>
                    <ModalHeader className="" toggle={() => this.dateModalClose()}>
                       Purchase/Hire Details
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="form-group">
                                <label>Delivery Date <strong className="text-danger">*</strong></label>
                                <Input 
                                    type="date" 
                                    id="datepicker" 
                                    value={bokkingDate.delivery_date}
                                    readonly={true}
                                    className="small "
                                />   
                                {error && this.state.dateSubmit==1 ? <span className="text-danger">{error.delivery_date ? error.delivery_date : ""}</span> : ""}
                            </div>

                            <div className="form-group">
                                <label>Operation Date <strong className="text-danger">*</strong></label>
                                <Input 
                                    type="date" 
                                    id="datepicker" 
                                    className="small "
                                    value={bokkingDate.operation_date}
                                    onChange = {(e) => this.handleOprationDate(e.target.value)} 
                                />   
                                {error && this.state.dateSubmit==1 ? <span className="text-danger">{error.operation_date ? error.operation_date : ""}</span> : ""}
                            </div>

                            <div className="form-group">
                                <label>Collection Date <strong className="text-danger">*</strong></label>
                                <Input 
                                    type="date" 
                                    id="datepicker" 
                                    className="small "
                                    value={bokkingDate.collection_date}
                                    onChange = {(e) => this.handleCollectionDate(e.target.value)} 
                                />   
                                {error && this.state.dateSubmit==1 ? <span className="text-danger">{error.collection_date ? error.collection_date : ""}</span> : ""}
                            </div>
                            
                        </div>           
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.dateModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={() =>this.handleUpdateDate()}>Update</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* date Modal */}

                <Modal size="ml" isOpen = {this.state.itemUsedModal} toggle={() => this.itemUsedModalClose()}>
                    <ModalHeader className="" toggle={() => this.itemUsedModalClose()}>
                        Item Used
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Set Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.setList && this.state.setList.map((item, index)=>(
                                    <tr key = {index}>
                                        <td>
                                            <Input
                                                type = "checkbox"
                                                className = "ml-0"
                                                onChange = {(e) => this.updateSetStatus(e.target.checked, index)}
                                                checked = { item.item_in_used == true ?  true : false }
                                            />
                                        </td>
                                        <td>{item.setData[0].set_name}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-primary" onClick={() =>this.itemUsedUpdate()}>Update</button>
                            <button className="btn btn-secondary ml-2" onClick={() =>this.itemUsedModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* item used Modal */}

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingList, activePage, totalItemsCount, limit, loading, redirect,error } = state.rootReducer.bookings;
    const {  refreshList  } = state.rootReducer.hospitals;
    const { editSet,set } = state.rootReducer.sets;
    return {
        bookingList,
        error,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set
    };
}

export default connect(mapStateToProps)(Index);