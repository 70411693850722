import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { hospitalActions } from "../../../../actions";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            editModal:false,
            deleteModal:false,
            name: "",
            id:"",
            hospitalInfo: {
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData:{address:[{name:""}]},
                departmentData:{department:[{name:""}]}
            },
            editHospitalInfo: {
                id:"",
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData:{address:[{name:""}]},
                departmentData:{department:[{name:""}]}
            },
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            submitModalFalg:0,
        };

        this.getList = this.getList.bind(this);
        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editModalOpen = this.editModalOpen.bind(this);
        this.editModalClose = this.editModalClose.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {

        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(hospitalActions.getList({search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
        
    }

    addNewModalOpen() {
        this.setState({ addNewModal: !this.state.addNewModal});
    }

    addNewModalClose() {
        this.setState({ 
            addNewModal: false,
            submitModalFalg:0,
            hospitalInfo: {
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData:{address:[{name:"",validate:false}]},
                departmentData:{department:[{name:"",validate:false}]}
            },
        });
    }

    nameHandler(val) {
        this.setState({
            name:val
        });
    }

    hospitalInfoHandler = (name, value) => {
        const { hospitalInfo } = this.state;
        hospitalInfo[name] = value;
        this.setState({
            hospitalInfo
        });
    };

    addMoreAddress = () => {
        const { hospitalInfo } = this.state;
        var addmore=hospitalInfo["addressData"].address;
        addmore.push({name:""});
        hospitalInfo["addressData"].address=addmore;
        this.setState({
            hospitalInfo
        });
    }; 

    addAddressHandler = (value,ind) => {
        const { hospitalInfo } = this.state;
        var addmore=hospitalInfo["addressData"].address;
        addmore[ind].name=value;
        hospitalInfo["addressData"].address=addmore;
        this.setState({
            hospitalInfo
        });
    }; 

 
    addMoreDepartment = () => {
        const { hospitalInfo } = this.state;
        var addmore=hospitalInfo["departmentData"].department;
        addmore.push({name:""});
        hospitalInfo["departmentData"].department=addmore;
        this.setState({
            hospitalInfo
        });
    }; 

    addDepartmentHandler = (value,ind) => {
        const { hospitalInfo } = this.state;
        var addmore=hospitalInfo["departmentData"].department;
        addmore[ind].name=value;
        hospitalInfo["departmentData"].department=addmore;
        this.setState({
            hospitalInfo
        });
    }; 

    handleSubmit() {
        const { hospitalInfo } = this.state;
        var address=[]; 
        var flag=0;
        hospitalInfo.addressData.address.map((sub)=>{
            if(sub.name=="")
            {
                flag=1;
                address.push({name:sub.name,validate:true});
            }
            else
            {
                address.push({name:sub.name,validate:false});
            }
           
        });

        var department=[];
        hospitalInfo.departmentData.department.map((sub)=>{
            if(sub.name=="")
            {
                flag=1;
                department.push({name:sub.name,validate:true});

            }
            else
            {
                department.push({name:sub.name,validate:false});

            }
        }); 

        hospitalInfo["addressData"].address=address;
        hospitalInfo["departmentData"].department=department;
        this.setState({
            hospitalInfo,
            submitModalFalg:1
        });

        if(flag==0 || hospitalInfo.name==""  || hospitalInfo.contact_person_name==""  || hospitalInfo.contact_person_phone_number=="" )
        {
            this.props.dispatch(hospitalActions.add(this.state.hospitalInfo));
        }


       
    }

    getSingle(id) {
        this.props.dispatch(hospitalActions.getById(id));
    }

    setEdit(data) {
        var address=[];
        data.addresses.map((sub)=>{
            address.push({name:sub.name,validate:false});
        });

        var department=[];
        data.departments.map((sub)=>{
            department.push({name:sub.name,validate:false});
        });

        this.setState({
            editHospitalInfo: {
                id:data._id,
                name:data.name,
                switch_board_phone_number: data.switch_board_phone_number,
                contact_person_name: data.contact_person_name,
                contact_person_phone_number: data.contact_person_phone_number,
                sac_code: data.sac_code,
                email: data.email,
                addressData:{address:address},
                departmentData:{department:department}
            }
        });
        this.editModalOpen();
    }
   
    editModalOpen() {
        this.setState({ editModal: !this.state.editModal});
    }

    editModalClose() {
        this.setState({ 
            editModal: false,
            submitModalFalg:0,
            editHospitalInfo: {
                id:"",
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData:{address:[{name:"",validate:false}]},
                departmentData:{department:[{name:"",validate:false}]}
            },
        });
    }

    editHospitalInfoHandler = (name, value) => {
        const { editHospitalInfo } = this.state;
        editHospitalInfo[name] = value;
        this.setState({
            editHospitalInfo
        });
    };

    editAddMoreAddress = () => {
        const { editHospitalInfo } = this.state;
        var addmore=editHospitalInfo["addressData"].address;
        addmore.push({name:""});
        editHospitalInfo["addressData"].address=addmore;
        this.setState({
            editHospitalInfo
        });
    }; 

    editAddressHandler = (value,ind) => {
        const { editHospitalInfo } = this.state;
        var addmore=editHospitalInfo["addressData"].address;
        addmore[ind].name=value;
        editHospitalInfo["addressData"].address=addmore;
        this.setState({
            editHospitalInfo
        });
    }; 

 
    editAddMoreDepartment = () => {
        const { editHospitalInfo } = this.state;
        var addmore=editHospitalInfo["departmentData"].department;
        addmore.push({name:""});
        editHospitalInfo["departmentData"].department=addmore;
        this.setState({
            editHospitalInfo
        });
    }; 

    editDepartmentHandler = (value,ind) => {
        const { editHospitalInfo } = this.state;
        var addmore=editHospitalInfo["departmentData"].department;
        addmore[ind].name=value;
        editHospitalInfo["departmentData"].department=addmore;
        this.setState({
            editHospitalInfo
        });
    }; 

    handleUpdate() {
        const { editHospitalInfo } = this.state;
        var address=[]; 
        var flag=0;
        editHospitalInfo.addressData.address.map((sub)=>{
            if(sub.name=="")
            {
                flag=1;
                address.push({name:sub.name,validate:true});
            }
            else
            {
                address.push({name:sub.name,validate:false});
            }
           
        });

        var department=[];
        editHospitalInfo.departmentData.department.map((sub)=>{
            if(sub.name=="")
            {
                flag=1;
                department.push({name:sub.name,validate:true});

            }
            else
            {
                department.push({name:sub.name,validate:false});

            }
        }); 

        editHospitalInfo["addressData"].address=address;
        editHospitalInfo["departmentData"].department=department;
        this.setState({
            editHospitalInfo,
            submitModalFalg:1
        });

        if(flag==0 || editHospitalInfo.name==""  || editHospitalInfo.contact_person_name==""  || editHospitalInfo.contact_person_phone_number=="" )
        {
            this.props.dispatch(hospitalActions.update(this.state.editHospitalInfo));
        }
        
    }

    deleteModalOpen() {
        this.setState({ deleteModal: true});
    }

    deleteModalClose() {
        this.setState({ 
            deleteModal: false,
            name:""
        });
    }

    deleteAddDepartment(ind){
        const { hospitalInfo } = this.state;
        var deleteh=hospitalInfo["departmentData"].department;
        deleteh.splice(ind,1);
        hospitalInfo["departmentData"].department=deleteh;
        this.setState({
            hospitalInfo
        });
    }

    deleteAddAddress(ind){
        const { hospitalInfo } = this.state;
        var deleteh = hospitalInfo["addressData"].address;
        deleteh.splice(ind,1);
        hospitalInfo["addressData"].address=deleteh;
        this.setState({
            hospitalInfo
        });
    }

    deleteEditDepartment(ind){
        const { editHospitalInfo } = this.state;
        var deleteh=editHospitalInfo["departmentData"].department;
        deleteh.splice(ind,1);
        editHospitalInfo["departmentData"].department=deleteh;
        this.setState({
            editHospitalInfo
        });
    }

    deleteEditAddress(ind){
        const { editHospitalInfo } = this.state;
        var deleteh=editHospitalInfo["addressData"].address;
        deleteh.splice(ind,1);
        editHospitalInfo["addressData"].address=deleteh;
        this.setState({
            editHospitalInfo
        });
    }

    handleDelete(id) {
        this.setState({ 
            id: id
        });
        this.deleteModalOpen();
    }

    doDelete() {
        this.props.dispatch(hospitalActions.delete({id:this.state.id}));
    }

    componentDidMount(){
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.refreshListHospital==true)
        {
            this.setState({ 
                activePage: 1
            },()=>{this.getList();});
            
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        if(nextProps.modal==false)
        {
            this.addNewModalClose();
        }


        if(nextProps.editModal==true)
        {
            this.setEdit(nextProps.list);
        }

        if(nextProps.editModal==false)
        {
            this.editModalClose();
        }

        if(nextProps.deleteModal==false)
        {
            this.deleteModalClose();
        }
        
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        const { lists,error,loading } = this.props;
        const { submitModalFalg } =this.state;
        return (
            <div>
                <Navbar activePage="hospitals"/>
                <div className="container">    
                    <div className="pt-4 row align-items-center">
                        <div className="col-md-3">
                            <div className="sub-menu">
                                <h1 className="heading">Hospitals</h1>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <form className="form-search">
                                <Input 
                                    className="form-control mr-sm-2" 
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />                
                            </form>
                        </div>  
                        <div className="col-md-3 text-right">
                            <button className="btn btn-primary add_option" onClick={this.addNewModalOpen}><i className="fa fa-plus"></i> <span>Add New</span></button>
                        </div>
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>              
                                    <th scope="col" style={{minWidth:"250px"}}>Hospital Name</th>
                                    <th scope="col" style={{minWidth:"100px"}}>Sac Code</th>
                                    <th scope="col" style={{minWidth:"100px"}}>Contact Person</th>
                                    <th scope="col" style={{minWidth:"100px"}}>Switch Board Phone</th>
                                    <th scope="col">Department</th>
                                    <th scope="col" className="text-right" width="100">Actions</th>                
                                </tr>
                            </thead>
                            <tbody>
                                {   lists && lists.map((item) => (
                                    <tr key={item._id}>           
                                        <td data-th="Hospital Name">
                                            <div className=""> 
                                                <strong>{item.name}</strong>   
                                                {   item.addresses && item.addresses.map((sub,subind) => (
                                                    <small key={subind} style={{"display":"block"}}>{sub.name}</small>
                                                ))}
                                            </div>
                                        </td>
                                        <td data-th="Sac Code">{item.sac_code}</td>
                                        <td data-th="Contact Person">{item.contact_person_name}</td>
                                        <td data-th="Switch Board Phone">{item.switch_board_phone_number}</td>
                                        <td data-th="Department">
                                            {item.departments.map((department,ind) =>(
                                                <div key={ind}>
                                                    { ind>0 &&
                                                    ","
                                                    }
                                                    {department.name}
                                                
                                                </div>
                                            ))}
                                        </td>
                                     
                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="dots">...</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">                       
                                                    <a className="dropdown-item" onClick={() => this.getSingle(item._id)} href="javascript:;">Edit</a>
                                                    <a className="dropdown-item" onClick={() => this.handleDelete(item._id)}  href="javascript:;">Delete</a>                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                { lists && lists.length==0 && loading==false && 
                                    <tr className="text-center">
                                        <td colSpan="6">No Record Found</td>
                                    </tr>
                                }
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                {lists && lists.length >0 &&
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Previous"
                    />
                }

                <Modal size="lg" isOpen={this.state.addNewModal} toggle={() => this.addNewModalClose()}>
                    <ModalHeader className="" toggle={() => this.addNewModalClose()}>
                        Add New Hospital
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Hospital Name <span className="text-danger">*</span></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.hospitalInfo.name}
                                            onChange = {(e) => this.hospitalInfoHandler("name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Switch Board Phone Number</label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.hospitalInfo.switch_board_phone_number}
                                            onChange = {(e) => this.hospitalInfoHandler("switch_board_phone_number", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.switch_board_phone_number ? error.switch_board_phone_number : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person Name <span className="text-danger">*</span></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.hospitalInfo.contact_person_name}
                                            onChange = {(e) => this.hospitalInfoHandler("contact_person_name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.contact_person_name ? error.contact_person_name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person Phone Number <span className="text-danger">*</span></label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.hospitalInfo.contact_person_phone_number}
                                            onChange = {(e) => this.hospitalInfoHandler("contact_person_phone_number", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.contact_person_phone_number ? error.contact_person_phone_number : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>SAC Code</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.hospitalInfo.sac_code}
                                            onChange = {(e) => this.hospitalInfoHandler("sac_code", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.sac_code ? error.sac_code : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.hospitalInfo.email}
                                            onChange = {(e) => this.hospitalInfoHandler("email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.email ? error.email : ""}</span> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    { this.state.hospitalInfo.addressData.address && this.state.hospitalInfo.addressData.address.map((sub,ind) => (
                                        <div className="form-group" key={ind}>
                                            <label>Hospital Address <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <div style={{width:"calc(100% - 30px)"}}>
                                                    <textarea className="form-control" name="" onChange = {(e) => this.addAddressHandler(e.target.value,ind)} value={sub.name} rows="2"></textarea> 
                                                </div>
                                                { ind>0 &&
                                                    <div>
                                                        <a className="ml-2" onClick={() => this.deleteAddAddress(ind)} href="javascript:;"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                }
                                            </div>
                                            {sub.validate==true ? <span className="text-danger">The address field is required.</span> : ""}
                                        </div> 
                                    ))}
                                    <div>
                                        <a href="javascript:;" onClick = {() => this.addMoreAddress()} className="sml-btn mt-2 mb-3"><i className="fa fa-plus"></i> Add More Address</a>
                                    </div>
                                    { this.state.hospitalInfo.departmentData.department && this.state.hospitalInfo.departmentData.department.map((sub,ind) => (
                                        <div className="form-group" key={ind}>
                                            <label>Hospital Department <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <div style={{width:"calc(100% - 30px)"}}>
                                                    <Input type="text" className="form-control"  onChange = {(e) => this.addDepartmentHandler(e.target.value,ind)} name="" value={sub.name} />
                                                </div>
                                                { ind>0 &&
                                                    <div>
                                                        <a className="ml-2" onClick={() => this.deleteAddDepartment(ind)} href="javascript:;"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                }
                                            </div>
                                            {sub.validate==true ? <span className="text-danger">The department field is required.</span> : ""}
                                        </div>  
                                    ))} 
                                    <div>
                                        <a href="javascript:;"  onClick = {() => this.addMoreDepartment()} className="sml-btn mt-2 mb-3"><i className="fa fa-plus"></i> Add More Department</a>
                                    </div>
                                </div>
                            </div>              
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.addNewModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}
             
                <Modal size="lg" isOpen={this.state.editModal} toggle={() => this.editModalClose()}>
                    <ModalHeader className="" toggle={() => this.editModalClose()}>
                        Edit Hospital
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Hospital Name <span className="text-danger">*</span></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.name}
                                            onChange = {(e) => this.editHospitalInfoHandler("name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Switch Board Phone Number </label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.switch_board_phone_number}
                                            onChange = {(e) => this.editHospitalInfoHandler("switch_board_phone_number", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.switch_board_phone_number ? error.switch_board_phone_number : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person Name <span className="text-danger">*</span></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.contact_person_name}
                                            onChange = {(e) => this.editHospitalInfoHandler("contact_person_name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.contact_person_name ? error.contact_person_name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person Phone Number <span className="text-danger">*</span></label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.contact_person_phone_number}
                                            onChange = {(e) => this.editHospitalInfoHandler("contact_person_phone_number", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.contact_person_phone_number ? error.contact_person_phone_number : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>SAC Code</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.sac_code}
                                            onChange = {(e) => this.editHospitalInfoHandler("sac_code", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.sac_code ? error.sac_code : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editHospitalInfo.email}
                                            onChange = {(e) => this.editHospitalInfoHandler("email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.email ? error.email : ""}</span> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6"> 
                                    { this.state.editHospitalInfo.addressData.address && this.state.editHospitalInfo.addressData.address.map((sub,ind) => (
                                        <div className="form-group" key={ind}>
                                            <label>Hospital Address <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <div style={{width:"calc(100% - 30px)"}}>
                                                    <textarea className="form-control" name="" onChange = {(e) => this.editAddressHandler(e.target.value,ind)} value={sub.name} rows="2"></textarea> 
                                                </div>
                                                { ind>0 &&
                                                    <div>
                                                        <a className="ml-2" onClick={() => this.deleteEditAddress(ind)} href="javascript:;"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                }
                                            </div>
                                            {sub.validate==true ? <span className="text-danger">The address field is required.</span> : ""}
                                        </div> 
                                    ))}
                                    <div>
                                        <a href="javascript:;" onClick = {() => this.editAddMoreAddress()} className="sml-btn mt-2 mb-3"><i className="fa fa-plus"></i> Add More Address</a>
                                    </div>
                                    { this.state.editHospitalInfo.departmentData.department && this.state.editHospitalInfo.departmentData.department.map((sub,ind) => (
                                        <div className="form-group" key={ind}>
                                            <label>Hospital Department <span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <div style={{width:"calc(100% - 30px)"}}>
                                                    <Input type="text" className="form-control"  onChange = {(e) => this.editDepartmentHandler(e.target.value,ind)} name="" value={sub.name} />
                                                </div>
                                                { ind>0 &&
                                                    <div>
                                                        <a className="ml-2" onClick={() => this.deleteEditDepartment(ind)} href="javascript:;"><i className="fa fa-trash"></i></a>
                                                    </div>
                                                }
                                            </div>
                                            {sub.validate==true ? <span className="text-danger">The department field is required.</span> : ""}
                                        </div>  
                                    ))} 
                                    <div>
                                        <a href="javascript:;"  onClick = {() => this.editAddMoreDepartment()} className="sml-btn mt-2 mb-3"><i className="fa fa-plus"></i> Add More Department</a>
                                    </div>
                                </div>
                            </div>    
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.editModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }

                        </div>
                    </ModalFooter>
                </Modal>

                {/* Edit Modal */}

                <Modal size="md" isOpen={this.state.deleteModal} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Hospital
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this hospital?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={() =>this.doDelete()}>Ok</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { lists, activePage, totalItemsCount, limit } = state.rootReducer.hospitals;
    const { error, modal, editModal, list, refreshListHospital, loading, deleteModal } = state.rootReducer.hospitals;
    return {
        lists,
        error,
        modal,
        editModal,
        refreshListHospital,
        list,
        loading,
        deleteModal,
        activePage,
        totalItemsCount,
        limit
    };
}

export default connect(mapStateToProps)(Index);