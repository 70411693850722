import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
import { surgeonActions } from "../../../../actions";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";


class Delete extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            
           
        };

       
        
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.doDelete = this.doDelete.bind(this);
       

    }


    deleteModalClose() {
        this.props.deleteModalClose();
    }


    doDelete() {
        this.props.dispatch(surgeonActions.delete({id:this.props.id}));
    }

   

   

    UNSAFE_componentWillReceiveProps(nextProps) {
        
        if(nextProps.deleteModal==false)
        {
            this.deleteModalClose();
        }

    }

    render() {
        
        const { loading } = this.props;
        return (
            <div>
                <Modal size="md" isOpen={this.props.deleteModalProps} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Surgeon
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this surgeon?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={() =>this.doDelete()}>Ok</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {   refreshList, loading, deleteModal } = state.rootReducer.surgeons;

    return {
        refreshList,
        loading,
        deleteModal,
    };
}

export default connect(mapStateToProps)(Delete);