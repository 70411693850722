import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";

import { repsBookingActions, setActions } from "../../../../actions";
import dateFormat from "dateformat";

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            kitSetDetailModal:false,
            kitSetData:[],
            kitDetailsClickFlag:0,
            kitDetails:[],
            cancelModal:false, 
            cancelId:0,
            searchOperationDate:""
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.cancelModalClose = this.cancelModalClose.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(repsBookingActions.getList({type:["loan"], reps_id:this.state.user.data._id,operation_date:this.state.searchOperationDate, status:["pending","booked","dispatch","return"],search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
    }

    handleSearchOperationDate(val){
        this.setState({
            searchOperationDate:val,
            activePage:1
        },()=>{this.getList();});

    }

    kitSetDetailModalClose(){
        this.setState({
            kitSetDetailModal :false,
            kitSetData:[]
        });
    }

    showKitDetails(kitDetails){

        if(this.state.kitDetailsClickFlag==1)
        {
            this.setState({
                kitDetails:kitDetails,
                kitSetDetailModal : true,
                kitDetailsClickFlag:0
            }); 
        }
      
    }

    handleKitDetails(id){

        this.setState({
            kitDetailsClickFlag:1
        });
       
        this.props.dispatch(setActions.getById(id));

    }

    cancelModalOpen(id){

        this.setState({
            cancelModal:true,
            cancelId:id
        });
    }

    cancelModalClose(){

        this.setState({
            cancelModal:false
        });

    }

    handleCancle(){
        this.props.dispatch(repsBookingActions.updateStatus({id: this.state.cancelId,status:"cancel"}));
    }

    capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

   
    componentDidMount(){
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(nextProps.refreshList==true || nextProps.redirect==true)
        {
            this.setState({ 
                activePage: 1,
                cancelModal:false
            },()=>{this.getList();}); 
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }    

        if(nextProps.editSet == true ) {
            this.showKitDetails(nextProps.set);
        }    
    }

    render() {
        const {bookingList, loading } = this.props;
        
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator="-";
        const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`;
        console.log(todayDate);
        return (
            <div>
                <Navbar activePage="bookings"/>
                <div className="container pt-1">
                    <div className="row pt-4 align-items-center">
                        <div className="col-md-4">
                            <ul className="sub-menu">
                                <li><Link to="/reps/open-booking" className="active">Open</Link></li>
                                <li><Link to="/reps/reject-booking">Rejected</Link></li>
                                <li><Link to="/reps/closed-booking">Closed</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <form className="form-search">
                                <Input 
                                    className="form-control mr-sm-2" 
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />             
                            </form>
                        </div>  
                        <div className="col-md-3 col-sm-6">
                            <div className="label_adjust">
                                <label className="small mb-0">Operation Date </label>
                                <span className="small ml-4 btn-clear"><a href="javascript:;" onClick={()=>this.handleSearchOperationDate("")}>Clear</a></span>
                                <Input 
                                    className="form-control form-control-sm mr-sm-2" 
                                    type="date"
                                    placeholder="Search.."
                                    value={this.state.searchOperationDate}
                                    onChange={(e) => this.handleSearchOperationDate(e.target.value)}
                                /> 
                            </div>            
                        </div>  
                        <div className="col-sm-2 text-right">
                            <Link className="btn btn-primary add_option" to="/reps/booking/add" ><i className="fa fa-plus"></i> <span>Add New</span></Link>
                        </div>
                    </div>

                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200">Hospital Name</th>
                                    <th scope="col" className="mobilehide">Contact Person</th>  
                                    <th scope="col" className="mobilehide">Kits Required</th>                 
                                    <th scope="col" style={{"minWidth": "100px"}} className="mobilehide">Sets</th>
                                    <th scope="col">Operation Name</th>             
                                    <th scope="col" width="200">Important Dates</th>
                                    <th scope="col" className="text-right" width="175">Actions</th>                
                                </tr>
                            </thead>
                            <tbody>
                                { bookingList && bookingList.map((item) => (
                                    <tr key={item._id}>
                                        <td width="200" data-th="Hospital Name" className="mobilehide_heading">
                                            <div className="">                   
                                                <Link to={"/reps/booking/details/"+item.bookings._id}>
                                                    <span><strong><span style={{"display": "block"}} className="theme-text">#{item.bookings.booking_id}</span>
                                                        {item.hospitalId.name}</strong></span><br />
                                                    { item.bookingSurgeonData.length>0 &&
                                                        <React.Fragment>
                                                            <span><small>Surgeon:</small> <strong>{item.bookingSurgeonData[0].SurgeonDetail[0].first_name+" "+item.bookingSurgeonData[0].SurgeonDetail[0].last_name}</strong></span><br />
                                                        </React.Fragment>    
                                                    }
                                                    <small className="mobilehide">Booked on {dateFormat(item.bookings.createdAt, "mmmm dS, yyyy")}</small>                          
                                                </Link>
                                            </div>
                                        </td>
                                        <td data-th="Contact Person" className="mobilehide">
                                            <div className="info-left">
                                                <strong>{item.hospitalId.contact_person_name} </strong><br />
                                                <small>Location: {item.bookings.hospital_address} </small><br />
                                                <small>Email: {item.hospitalId.email}</small><br />
                                                <small>Phone: {item.hospitalId.contact_person_phone_number}</small>
                                            </div>
                                        </td>
                                        <td data-th="Kits Required" className="mobilehide">
                                            <small>
                                                { item.bookingkits && item.bookingkits.map((kit,ind)=>(
                                                    <React.Fragment key={ind}>
                                                        <div><a href="javascript:;" >{kit.kits[0].name} </a></div>
                                                    </React.Fragment>
                                                ))}
                                            </small>
                                        </td>
                                        <td data-th="Sets" className="mobilehide">
                                            <small>
                                                { item.bookingKitSetId && item.bookingKitSetId.map((kset,ind)=>(
                                                    <React.Fragment key={ind}>
                                                        <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div>
                                                    </React.Fragment>
                                                ))}                                               
                                            </small>                        
                                        </td>
                                        <td data-th="Operation Name">
                                            { item.bookings.operation_name === "Other" 
                                                ?<span> {item.bookings.other_opration} </span>
                                                :<span> {item.bookings.operation_name} </span>
                                            }
                                        </td>
                                        <td data-th="Important Dates" className="mobilehide_heading">
                                            <small>
                                                <span className="mobilehide">Delivery Date: <strong>{dateFormat(item.bookings.delivery_date, "mmmm dS, yyyy")} {item.bookings.delivery_date_slot}</strong><br /></span>
                                                Operation Date: <strong>{dateFormat(item.bookings.operation_date, "ddd, mmmm dS, yyyy")} {item.bookings.operation_date_slot}</strong><br />
                                                <span className="mobilehide">Collection Date: <strong>{dateFormat(item.bookings.collection_date, "mmmm dS, yyyy")}</strong></span>
                                            </small>
                                        </td> 
                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <span  
                                                    className={"capsule " +(item.bookings.status=="booked" ? "green-dim " : "red-dim ") +" mr-2"} 
                                                >
                                                    { item.bookings.status=="dispatch" && 
                                                        <div>Dispatched</div>
                                                    }
                                                    { item.bookings.status!="dispatch" && 
                                                        this.capitalize(item.bookings.status)
                                                    }
                                                </span>
                                                <span><Link className="btn-link mr-2 desktophide" to={"/reps/booking/details/"+item.bookings._id}>More Details</Link></span>

                                                <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="dots">...</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <Link className="dropdown-item mobilehide" to={"/reps/booking/details/"+item.bookings._id}>More Details</Link>
                                                  
                                                    { item.bookings.status === "pending"  &&
                                                        <Link className="dropdown-item" to={"/reps/booking/edit/"+item.bookings._id}>Edit</Link>
                                                    }
                                                   
                                                </div>
                                            </div>
                                        </td>  
                                       
                                    </tr>
                                ))}
                                { bookingList && bookingList.length==0 && loading==false && 
                                    <tr className="text-center">
                                        <td colSpan="6">No Record Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {bookingList && bookingList.length >0 &&
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Previous"
                    />
                }

             

                <Modal size="md" isOpen={this.state.cancelModal} toggle={() => this.cancelModalClose()}>
                    <ModalHeader className="" toggle={() => this.cancelModalClose()}>
                        Cancel Booking
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to cancel this booking?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.cancelModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={() =>this.handleCancle()}>Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}

                <Modal size="ml" isOpen={this.state.dispatchModal} toggle={() => this.dispatchModalClose()}>
                    <ModalHeader className="" toggle={() => this.dispatchModalClose()}>
                        Dispatch
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to dispatch this booking?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.dispatchModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={() =>this.handleDispatch()}>Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Cancle Modal*/}

                <Modal size="lg" isOpen={this.state.kitSetDetailModal} toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader className="" toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules && this.state.kitDetails.setProductModules.map((product,ind)=>(
                                    <tr key={ind}>
                                        <td>{product.productID.code}</td>
                                        <td>{product.productID.name}</td>
                                        <td>{product.quantity}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary" onClick={() =>this.kitSetDetailModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingList, activePage, totalItemsCount, limit, loading, redirect } = state.rootReducer.repsBookings;
    const { error, refreshList  } = state.rootReducer.hospitals;
    const { editSet,set } = state.rootReducer.sets;
    return {
        bookingList,
        error,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set
    };
}

export default connect(mapStateToProps)(Index);