import { bookingConstants } from "../constants";
import { bookingService } from "../services";

export const bookingActions = {
    getHospitalsList,
    getRepsList,
    getKitSetByKit,
    getList,
    add,
    getById,
    getHospitalDetailsById,
    update,
    updateStatus,
    saveReturn,
    countByStatus,
    countDispatch,
    saveDispatchs,
    saveCollections,
    updateBookingDates,
    getLog,
    delete: _delete
};

function getHospitalsList(search) {
    return dispatch => {
        dispatch(request());

        bookingService.getHospitalsList(search)
            .then(
                hospitalsList => dispatch(success(hospitalsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GETALLHOSPITALS_REQUEST }; }
    function success(hospitalsList) { return { type: bookingConstants.GETALLHOSPITALS_SUCCESS, hospitalsList }; }
    function failure(error) { return { type: bookingConstants.GETALLHOSPITALS_FAILURE, error }; }
}

function getRepsList(search) {
    return dispatch => {
        dispatch(request());

        bookingService.getRepsList(search)
            .then(
                repsList => dispatch(success(repsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GETALLREPS_REQUEST }; }
    function success(repsList) { return { type: bookingConstants.GETALLREPS_SUCCESS, repsList }; }
    function failure(error) { return { type: bookingConstants.GETALLREPS_FAILURE, error }; }
}

function getKitSetByKit(search) {
    return dispatch => {
        dispatch(request());

        bookingService.getKitSetByKit(search)
            .then(
                kitSetList => dispatch(success(kitSetList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GETKITSETBYKIT_REQUEST }; }
    function success(kitSetList) { return { type: bookingConstants.GETKITSETBYKIT_SUCCESS, kitSetList }; }
    function failure(error) { return { type: bookingConstants.GETKITSETBYKIT_FAILURE, error }; }
}


function getList(search) {
    return dispatch => {
        dispatch(request());

        bookingService.getList(search)
            .then(
                bookingList => dispatch(success(bookingList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GETALLBOOKINGS_REQUEST }; }
    function success(bookingList) { return { type: bookingConstants.GETALLBOOKINGS_SUCCESS, bookingList }; }
    function failure(error) { return { type: bookingConstants.GETALLBOOKINGS_FAILURE, error }; }
}

function add(info) {
    return dispatch => {
        dispatch(request());

        bookingService.add(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGADD_REQUEST }; }
    function success(info) { return { type: bookingConstants.BOOKINGADD_SUCCESS, info }; }
    function failure(error) { return { type: bookingConstants.BOOKINGADD_FAILURE, error }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        bookingService.getById(id)
            .then(
                bookingInfo => dispatch(success(bookingInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGEDIT_REQUEST }; }
    function success(bookingInfo) { return { type: bookingConstants.BOOKINGEDIT_SUCCESS, bookingInfo }; }
    function failure(error) { return { type: bookingConstants.BOOKINGEDIT_FAILURE, error }; }
}

function getHospitalDetailsById(id) {
    return dispatch => {
        dispatch(request());

        bookingService.getHospitalDetailsById(id)
            .then(
                hospitalDetails => dispatch(success(hospitalDetails)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GETHOSPITALADDRESS_REQUEST }; }
    function success(hospitalDetails) { return { type: bookingConstants.GETHOSPITALADDRESS_SUCCESS, hospitalDetails }; }
    function failure(error) { return { type: bookingConstants.GETHOSPITALADDRESS_FAILURE, error }; }
}

function update(info) {
    return dispatch => {
        dispatch(request());

        bookingService.update(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGUPDATE_REQUEST }; }
    function success(info) { return { type: bookingConstants.BOOKINGUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: bookingConstants.BOOKINGUPDATE_FAILURE, error }; }
}

function updateStatus(info) {
    return dispatch => {
        dispatch(request());

        bookingService.updateStatus(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGSTATUSUPDATE_REQUEST }; }
    function success(info) { return { type: bookingConstants.BOOKINGSTATUSUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: bookingConstants.BOOKINGSTATUSUPDATE_FAILURE, error }; }
}

function saveReturn(returnInfo) {
    return dispatch => {
        dispatch(request());

        bookingService.saveReturn(returnInfo)
            .then(
                returnInfo => dispatch(success(returnInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.SETRETURN_REQUEST }; }
    function success(returnInfo) { return { type: bookingConstants.SETRETURN_SUCCESS, returnInfo }; }
    function failure(error) { return { type: bookingConstants.SETRETURN_FAILURE, error }; }
}

function countByStatus(countInfo) {
    return dispatch => {
        dispatch(request());

        bookingService.countByStatus(countInfo)
            .then(
                countInfo => dispatch(success(countInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGCOUNT_REQUEST }; }
    function success(countInfo) { return { type: bookingConstants.BOOKINGCOUNT_SUCCESS, countInfo }; }
    function failure(error) { return { type: bookingConstants.BOOKINGCOUNT_FAILURE, error }; }
}

function countDispatch(countDispatch) {
    return dispatch => {
        dispatch(request());

        bookingService.countDispatch(countDispatch)
            .then(
                countDispatch => dispatch(success(countDispatch)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGDISPATCH_COUNT_REQUEST }; }
    function success(countDispatch) { return { type: bookingConstants.BOOKINGDISPATCH_COUNT_SUCCESS, countDispatch }; }
    function failure(error) { return { type: bookingConstants.BOOKINGDISPATCH_COUNT_FAILURE, error }; }
}


function saveDispatchs(info) {
    return dispatch => {
        dispatch(request());

        bookingService.saveDispatchs(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGSAVEDISPATCH_REQUEST }; }
    function success(saveDispatch) { return { type: bookingConstants.BOOKINGSAVEDISPATCH_SUCCESS, saveDispatch }; }
    function failure(error) { return { type: bookingConstants.BOOKINGSAVEDISPATCH_FAILURE, error }; }
}

function saveCollections(info) {
    return dispatch => {
        dispatch(request());

        bookingService.saveCollections(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGSAVECOLLECTION_REQUEST }; }
    function success(saveCollections) { return { type: bookingConstants.BOOKINGSAVECOLLECTION_SUCCESS, saveCollections }; }
    function failure(error) { return { type: bookingConstants.BOOKINGSAVECOLLECTION_FAILURE, error }; }
}

function updateBookingDates(info) {
    return dispatch => {
        dispatch(request());

        bookingService.updateBookingDates(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGUPDATEDATE_REQUEST }; }
    function success(updateBookingDates) { return { type: bookingConstants.BOOKINGUPDATEDATE_SUCCESS, updateBookingDates }; }
    function failure(error) { return { type: bookingConstants.BOOKINGUPDATEDATE_FAILURE, error }; }
}



function getLog(info) {
    return dispatch => {
        dispatch(request());

        bookingService.getLog(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOKINGLOG_REQUEST }; }
    function success(logList) { return { type: bookingConstants.BOOKINGLOG_SUCCESS, logList }; }
    function failure(error) { return { type: bookingConstants.BOOKINLOG_FAILURE, error }; }
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        bookingService.delete(id)
            .then(
                kit => { 
                    console.log(kit);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: bookingConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: bookingConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: bookingConstants.DELETE_FAILURE, id, error }; }
}