import { authHeader } from "../helpers";
import { APIURL } from "../constants/config";

export const repsBookingService = {
    getHospitalsList,
    getRepsList,
    getKitSetByKit,
    add,
    getList,
    getById,
    getHospitalDetailsById,
    update,
    updateStatus,
    saveReturn,
    delete: _delete
};

function getHospitalsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"hospitals/searchHospitals", requestOptions).then(handleResponse)
        .then(hospitalsList => {
            return hospitalsList;
        });
}

function getRepsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"users/searchUsers", requestOptions).then(handleResponse)
        .then(repsList => {
            return repsList;
        });
}

function getKitSetByKit(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"sets/getAllSetsUsingKitIdAndBookingIdForEdit", requestOptions).then(handleResponse)
        .then(kitSetList => {
            return kitSetList;
        });
}


function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"reps/booking/getBookings", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(APIURL+`reps/booking/editBooking/${id}`, requestOptions).then(handleResponse);
}

function getHospitalDetailsById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(APIURL+`hospitals/getHospital/${id}`, requestOptions).then(handleResponse);
}

function add(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(APIURL+"reps/booking/saveBookings", requestOptions).then(handleResponse);
}

function update(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(APIURL+"reps/booking/updateBookings", requestOptions).then(handleResponse);
}

function updateStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(APIURL+"booking/updateBookingStatus", requestOptions).then(handleResponse);
}

function saveReturn(returnInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(returnInfo)
    };

    return fetch(APIURL+"booking/saveReturns", requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id)
    };

    return fetch(APIURL+"hospitals/deleteHospitals", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}