import { setConstants } from "../constants";

const initialState =  { loading: null, usersList: null, } || {};

export function sets(state = initialState, action) {
    switch (action.type) {
    case setConstants.GETSETLIST_REQUEST:
        return {
            loading: true
        };
    case setConstants.GETSETLIST_SUCCESS:
        return {
            loading: false,
            setsList: action.setsList.data,
            activePage: action.setsList.page,
            totalItemsCount: action.setsList.total_count,
            limit: action.setsList.page_count
        };
    case setConstants.GETSETLIST_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case setConstants.GETKITSLIST_REQUEST:
        return {
            loading: true,
            
        };
    case setConstants.GETKITSLIST_SUCCESS:
        return {
            loading: false,
            kitsList: action.kitsList.data,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.GETKITSLIST_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case setConstants.GETPRODUCTSLIST_REQUEST:
        return {
            loading: true
        };
    case setConstants.GETPRODUCTSLIST_SUCCESS:
        return {
            loading: false,
            productsList: action.productsList.data
        };
    case setConstants.GETPRODUCTSLIST_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case setConstants.SETADD_REQUEST:
        return {
            loading: true,
            redirect: false,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETADD_SUCCESS:
        return {
            loading: false,
            redirect: true,
            modal:false,
            refreshList:true
        };
    case setConstants.SETADD_FAILURE:
        return { 
            loading: false,
            error: action.error,
            redirect: false,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETEDIT_REQUEST:
        return {
            loading: true,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETEDIT_SUCCESS:
        return {
            loading: false,
            kitsList: state.kitsList,
            productsList: state.productsList,
            set:action.set.data,
            editSet:true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETEDIT_FAILURE:
        return { 
            loading: false,
            error: action.error,
            setsList: state.setsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETUPDATE_REQUEST:
        return {
            loading: true,
            setsList: state.setsList,
            set: state.set,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.SETUPDATE_SUCCESS:
        return {
            loading: false,
            setsList: state.setsList, 
            redirect: true,
            modal:false,
            refreshList:true
        };
    case setConstants.SETUPDATE_FAILURE:
        return { 
            loading: false,
            error: action.error,
            setsList: state.setsList,
            set: state.set,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case setConstants.DELETE_REQUEST:
        // add 'deleting:true' property to set being deleted
        return {
            ...state,
            loading: true,
            setsList: state.setsList.map(set =>
                set._id === action.id
                    ? { ...set, deleting: true }
                    : set
            ),
            modal: false
        };
    case setConstants.DELETE_SUCCESS:
        // remove deleted set from state
        return {
            loading: false,
            deleteModal:false,
            refreshList:true,
            modal: false
        };
    case setConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to set 
        return {
            ...state,
            loading: false,
            setsList: state.setsList.map(set => {
                if (set._id === action.id) {
                    const { ...setCopy } = set;
                    // return copy of set with 'deleteError:[error]' property
                    return { ...setCopy, deleteError: action.error };
                }

                return set;
            }),
            modal: false
        };
    default:
        return state;
    }
}