import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./DashboardSidebar";
import { bookingRequestActions, bookingActions, setActions } from "../../../../actions";
//import dateFormat from "dateformat";
import Select from "react-select";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator="-";
const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

const head = [
    { title: "BOOKING",dataIndex: "booking"},
    { title: "HOSPITAL NAME",dataIndex: "hospital_name"},
    { title: "SET",dataIndex: "set"}
   
];

class BookingDispatch extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal:false,
            kitSetData:[],
            kitDetailsClickFlag:0,
            kitDetails:[],
            dispatchModal:false, 
            acceptModal:false,
            bookingId:0,
            users:[],
            selectedUser:[{label:"Booked By",value:""}],
            selectedUserId: "",
            hospitals:[],
            selectedHospital:[{label:"All Hospital",value:""}],
            selectedHospitalId: "",
            requestCount:0,
            rejectComment: "",
            trackers:[{label:"A",value:"A"},{label:"B",value:"B"}],
            selectedTrackers:[],
            date:todayDate,
            dispatch:{
                courier:"",
                dispatchBoxesData:[{box_type:"",number_of_box:""}],
                tracker:"A",
                bookingId:"",
                log_status:"dispatched",
                log_by_id:""
            },
            boxes:[{box_type:"",number_of_box:""}],
            submitFlag:0,
            dayActive:"today",
            dataXlsx:[],
            detailsModalResult:[],
            detailsModal:false
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.handleRejectComment = this.handleRejectComment.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.dispatchModalClose = this.dispatchModalClose.bind(this);
        this.setSelectedOptionUser = this.setSelectedOptionUser.bind(this);
        this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
        this.setSelectedOptionTracker = this.setSelectedOptionTracker.bind(this);
        this.detailsModalOpen = this.detailsModalOpen.bind(this);
        this.detailsModalClose = this.detailsModalClose.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    toDay(){

        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator="-";
        const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

        this.setState({ 
            dayActive: "today",
            date:todayDate
        },() => {this.getList();});

      

    }
    nextDays(){
        let newDate = new Date();
        let date = newDate.getDate()+1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator="-";
        const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

        this.setState({ 
            dayActive: "tomorrow" ,
            date:todayDate
        },() => {this.getList();});
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        if(totalItemsCount==undefined)
        {
            totalItemsCount=0;
        }
        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(bookingRequestActions.getList({bookedByFilter: this.state.selectedUserId, hospitalFilter: this.state.selectedHospitalId, type:["loan"],status:["booked","dispatch"],deliveryDateFiter:this.state.date,search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    getDropdown(){
        this.props.dispatch(bookingActions.getHospitalsList({search: ""}));
        this.props.dispatch(bookingActions.getRepsList({search: ""}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
    }

    kitSetDetailModalClose(){
        this.setState({
            kitSetDetailModal :false,
            kitSetData:[]
        });
    }

    showKitDetails(kitDetails){

        if(this.state.kitDetailsClickFlag==1)
        {
            this.setState({
                kitDetails:kitDetails,
                kitSetDetailModal : true,
                kitDetailsClickFlag:0
            }); 
        }
      
    }

    handleKitDetails(id){

        this.setState({
            kitDetailsClickFlag:1
        });
       
        this.props.dispatch(setActions.getById(id));

    }

    setSelectedOptionUser(e){
        var selectedUser=[{value:e.value, label:e.label}];
        this.setState({
            selectedUser,
            selectedUserId: e.value
        },()=>{this.getList();});
    } 

    setSelectedOptionHospital(e){
        var selectedHospital=[{value:e.value, label:e.label}];
        this.setState({
            selectedHospital,
            selectedHospitalId: e.value
        },()=>{this.getList();});
    } 

    setSelectedOptionTracker(e){
        var selectedTrackers=[{value:e.value, label:e.label}];
        var dispatch=this.state.dispatch;
        dispatch.tracker=e.value;
        this.setState({
            selectedTrackers,
            dispatch:dispatch
        });
    } 

    handleCourier(val){
        var dispatch=this.state.dispatch;
        dispatch.courier=val;
        this.setState({
            dispatch:dispatch
        });

    }

    handleBoxType(val,index){
        var { boxes } = this.state;
        boxes[index].box_type=val;

        var dispatch=this.state.dispatch;
        dispatch.dispatchBoxesData=boxes;

        this.setState({
            boxes:boxes,
            dispatch:dispatch
        });



    }

    handleBoxNumber(val,index){
        var { boxes } = this.state;
        if(val!="" && val<1)
        {
            val="";
        }
        boxes[index].number_of_box=val;
        var dispatch=this.state.dispatch;
        dispatch.dispatchBoxesData=boxes;

        this.setState({
            boxes:boxes,
            dispatch:dispatch
        });

        console.log(this.state.dispatch);

    }

    addMoreBox(){
        var { boxes } = this.state;
        boxes.push({box_type:"",number_of_box:""});
        var dispatch=this.state.dispatch;
        dispatch.dispatchBoxesData=boxes;
        this.setState({
            boxes:boxes,
            dispatch:dispatch
        });
    }

    handleBoxRemove(ind){
        const { boxes } = this.state;
        boxes.splice(ind,1);
        var dispatch=this.state.dispatch;
        dispatch.dispatchBoxesData=boxes;
        this.setState({
            boxes:boxes,
            dispatch:dispatch
        });
   
    }

    dispatchModalOpen(id){
        
        this.setState({
            dispatchModal:true,
            bookingId:id,
            dispatch:{
                courier:"",
                dispatchBoxesData:[{box_type:"",number_of_box:""}],
                tracker:"A",
                bookingId:id,
                log_status:"dispatched",
                log_by_id:this.state.user.data._id
            },
            boxes:[{box_type:"",number_of_box:""}],
            submitFlag:0
        });
    }

    dispatchModalClose(){

        this.setState({
            dispatchModal:false,
            submitFlag:0,
        });

    }

    detailsModalOpen(item){
        this.setState({
            detailsModal:true,
            detailsModalResult:item
        });
    }

    detailsModalClose(){
        this.setState({
            detailsModal:false,
        });
    }

    handleRejectComment(comment) {
        this.setState({
            rejectComment: comment
        });
    }

    handleDispatch(){
        this.setState({
            submitFlag: 1
        });
        var flag=0;

        this.state.boxes.map((item)=>{
            if(item.box_type=="" || item.number_of_box=="")
            {
                flag=1; 
            }
        });

     
        if(this.state.dispatch.courier=="" || this.state.dispatch.tracker=="")
        {
            this.props.dispatch(bookingActions.saveDispatchs(this.state.dispatch));
        }
        else if(flag==0)
        {
            this.props.dispatch(bookingActions.saveDispatchs(this.state.dispatch));
        }
        
        
    }


    showHospitalsList(hospitalsList) {
        var tempHospitals = [{label:"All Hospital",value:""}];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        this.setState({
            hospitals: tempHospitals,
        });
    }

    showRepsList(repsList) {
        var tempRepsList = [{label:"Booked By",value:""}];
        repsList.map((item)=> {
            var option = {value: item._id, label: item.first_name+" "+item.last_name};
            tempRepsList.push(option);
        });
        this.setState({
            users: tempRepsList
        });
    }

    showXlsData(data){

        var dataXlsx=[];
        var kitset="";

        data && data.map((item) => {
            kitset="";

            item.bookingKitSetId && item.bookingKitSetId.map((kset)=>{
                if(kitset!="")
                {
                    kitset+="\n"+kset.setId[0].set_name;
                }
                else
                {
                    kitset+=kset.setId[0].set_name; 
                }
                
            }); 
          
                                                         
            dataXlsx.push({
                "booking":item.bookings.po_number,
                "hospital_name": item.hospitalId.name,
                "set": kitset,
                "boxes": "",
             
            });
        });


        this.setState({
            dataXlsx: dataXlsx
        });
     
    }


    capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

   
    componentDidMount(){
        this.getList();
        this.getDropdown();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(nextProps.refreshList==true || nextProps.redirect==true)
        {
            this.setState({ 
                activePage: 1,
                dispatchModal:false,
            },()=>{this.getList();}); 
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
           
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }    

        if(nextProps.editSet == true ) {
            this.showKitDetails(nextProps.set);
        }

        if(nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if(nextProps.hospitalsList !== undefined) {
            this.showHospitalsList(nextProps.hospitalsList);
        }

        if(nextProps.bookingList !== undefined) {
            this.showXlsData(nextProps.bookingList);
        }


    }


    render() {
        const {bookingList, loading } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }

        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

        const { error } = this.props;
        //const { username, password} = this.state;
        return (
            <div>
                <Navbar activePage="dashboard"/>
                <main className="offset">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-lg-2 side-filters">     
                                <span className="side-heading">Dashboard</span>
                                <DashboardSidebar activePage="dispatch" dispatchCount={this.state.totalItemsCount}/>
                            </div>
                           
                            <div className="col-lg-10">
                                <div className="ground">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <div className="sub-menu"> 
                                                <div className="btn-group" role="group" aria-label="Basic example">                   
                                                    <button type="button" className={"btn btn-secondary " +(this.state.dayActive=="today" ? "active " : "")} onClick={()=>this.toDay()}>Today</button>
                                                    <button type="button" className={"btn btn-secondary " +(this.state.dayActive=="tomorrow" ? "active " : "")} onClick={()=>this.nextDays()}>Tomorrow</button> 
                                                    
                                                </div> 
                                            </div>                                            
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <form className="form-search">
                                                <Input 
                                                    className="form-control mr-sm-2" 
                                                    type="text"
                                                    placeholder="Search.."
                                                    value={this.state.search}
                                                    onChange={(e) => this.handleSearch(e.target.value)}
                                                />    
                                            </form>
                                        </div>
                                        <div className="col-md-4 text-right">
                                            {/*<button type="button" className="btn btn-default"> Print </button>*/}
                                            <ExportSheet
                                                header={head}
                                                fileName={"booking-dispatch"}
                                                dataSource={this.state.dataXlsx}
                                                xlsx={XLSX}
                                                className="btn btn-default add_option"
                                            >
                                                <button className="btn btn-default add_option">Print</button>
                                            </ExportSheet>
                                        </div>
                                    </div>
                                    <div className="table-scroll mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{minWidth:"200px"}}>Booking #</th>
                                                    <th scope="col" >Hospital Name </th>
                                                    <th scope="col" >SET</th>
                                                    <th scope="col" className="text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                { bookingList && bookingList.map((item) => (
                                                    <tr key={item._id}>
                                                        <td width="200" className="align-top">
                                                            <Link to={"/admin/booking/details/"+item.bookings._id}>
                                                                <span><strong> {item.bookings.booking_id} </strong></span>
                                                            </Link>
                                                        </td>
                                                        <td className="align-top">
                                                            <div className="">                   
                                                                {item.hospitalId.name}
                                                            </div>
                                                        </td>
                                                        <td className="align-top">
                                                            <small>
                                                                { item.bookingKitSetId && item.bookingKitSetId.map((kset,ind)=>(
                                                                    <React.Fragment key={ind}>
                                                                        <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div>
                                                                    </React.Fragment>
                                                                ))} 
                                                               
                                                            </small>                        
                                                        </td>
                                                        
                                                        <td className="text-right align-top">
                                                            {item.bookings.status=="booked" &&                              
                                                                <a href="javascript:;" onClick={()=>this.dispatchModalOpen(item.bookings._id)} className="btn btn-primary ml-2">Dispatch</a>
                                                            }
                                                            {item.bookings.status=="dispatch" &&   
                                                                <React.Fragment>  
                                                                    <span className="capsule green-dim mb-2">Dispatched</span>
                                                                    <a href="javascript:;" onClick={()=>this.detailsModalOpen(item.bookingdispatches)} className="btn mb-2 ">Details</a>

                                                                </React.Fragment> 
                                                            }
                                                        </td>  
                                                    </tr>
                                                ))}
                                                { bookingList && bookingList.length==0 && loading==false && 
                                                    <tr className="text-center">
                                                        <td colSpan="5">No Record Found</td>
                                                    </tr>
                                                } 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                {bookingList && bookingList.length >0 &&
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                }

                             

                                <Modal size="md" isOpen={this.state.dispatchModal} toggle={() => this.dispatchModalClose()}>
                                    <ModalHeader className="" toggle={() => this.dispatchModalClose()}>
                                       Add Dispatch
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="form-group">
                                            <label>Courier</label>
                                            <Input type="text" onChange={(e) => this.handleCourier(e.target.value)}  className="form-control" name="" />
                                            {error  && this.state.submitFlag==1 ? <span className="text-danger">{error.courier ? error.courier : ""}</span> : ""}
                                        </div>
                                        <div className="form-group" style={{display:"none"}}>
                                            <label>Tracker</label>
                                            <Select 
                                                value={this.state.selectedTrackers}
                                                onChange={this.setSelectedOptionTracker}
                                                options={this.state.trackers}
                                                className="small text-left"
                                            />
                                            {error  && this.state.submitFlag==1 ? <span className="text-danger">{error.tracker ? error.tracker : ""}</span> : ""}
                                        </div>
                                        <div className="form-group">       
                                            <label>Number of Boxes</label>
                                            {this.state.boxes && this.state.boxes.map((item,index) => (
                                                <div className="form-group row" key={index}>
                                                    <div className="col-sm-6 mb-3">              
                                                        <Input type="text" value={item.box_type} onChange={(e)=>this.handleBoxType(e.target.value,index)} className="form-control" placeholder="Box Type" name="" />
                                                        {item.box_type=="" && this.state.submitFlag==1 ? <span className="text-danger">Please enter box type</span> : ""}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">              
                                                        <Input type="number" value={item.number_of_box} onChange={(e)=>this.handleBoxNumber(e.target.value,index)} className="form-control" name="" placeholder="Number of Boxes" />
                                                        {item.number_of_box=="" && this.state.submitFlag==1 ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                                    </div>
                                                    <div className=""> 
                                                        { index!=0 &&
                                                                <i className="fa fa-trash" onClick={()=>this.handleBoxRemove(index)}></i>   
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                            <a href="javascript:;" className="sml-btn" onClick={()=>this.addMoreBox()}><i className="fa fa-plus"></i> Add More</a>   
                                        </div> 
                                       
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button onClick={() => this.dispatchModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                            {loading==false &&
                                                <button className="btn btn-primary" onClick={() =>this.handleDispatch()}>Ok</button>
                                            }
                                            {loading==true && 
                                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                            }
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Dispatch Modal */}

                                <Modal size="md" isOpen={this.state.detailsModal} toggle={() => this.detailsModalClose()}>
                                    <ModalHeader className="" toggle={() => this.detailsModalClose()}>
                                        Dispatched Details
                                    </ModalHeader>
                                    <ModalBody>
                                        {this.state.detailsModalResult.map((details,index) =>(
                                            <React.Fragment key={index}>
                                                <div className="form-group" >
                                                    <strong>Courier</strong>
                                                    <div>{details.courier}</div>
                                                </div>
                                               
                                                <div className="form-group">       
                                                    <strong>Number of Boxes</strong>
                                                    {details.bookingdispatchboxes && details.bookingdispatchboxes.map((box,ind) => (
                                                        <React.Fragment key={ind}>
                                                            <div className="form-group row">
                                                                <div className="col">              
                                                                    {box.box_type} - {box.number_of_box} {box.number_of_box>1 ? "Boxes" :"Box"}
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div> 
                                            </React.Fragment>
                                        ))}
                                       
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button className="btn btn-secondary" onClick={() =>this.detailsModalClose()}>Close</button>
                                        </div>
                                    </ModalFooter>
                                </Modal>
                                {/* Dispatch Details Modal */}

                               
                                <Modal size="lg" isOpen={this.state.kitSetDetailModal} toggle={() => this.kitSetDetailModalClose()}>
                                    <ModalHeader className="" toggle={() => this.kitSetDetailModalClose()}>
                                        Set Details
                                    </ModalHeader>
                                    <ModalBody>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Item Code</th>
                                                    <th>Item Name</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.kitDetails.setProductModules && this.state.kitDetails.setProductModules.map((product,ind)=>(
                                                    <tr key={ind}>
                                                        <td>{product.productID.code}</td>
                                                        <td>{product.productID.name}</td>
                                                        <td>{product.quantity}</td>
                                                    </tr>
                                                ))}
                                                
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="">
                                            <button className="btn btn-secondary" onClick={() =>this.kitSetDetailModalClose()}>Close</button>
                                        </div>
                                    </ModalFooter>
                                </Modal>

                                {/* Set Details Modal */}

                            </div>
                        </div>{/*<!--/row-->*/}
                    </div>
                </main>

          
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { hospitalsList, repsList, refreshList,error, loading } = state.rootReducer.bookings;
    const { bookingList, activePage, totalItemsCount, limit, redirect } = state.rootReducer.bookingRequests;
    const { editSet,set } = state.rootReducer.sets;
    return {
        bookingList,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
        hospitalsList,
        repsList,
        error
    };
}

export default connect(mapStateToProps)(BookingDispatch);