import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {  bookingActions, returnBookingActions, collectionBookingActions} from "../../../../actions";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator="-";
const todayDate =`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

class DashboardSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestCount: this.props.requestCount,
            dispatchCount: this.props.dispatchCount,
            returnCount: this.props.returnCount,
            collectionCount: this.props.collectionCount,
            today:todayDate
        };
    }
  

    getCount(){
        this.props.dispatch(bookingActions.countByStatus({type:["loan"],status:["pending"],deliveryDateFiter:""}));
        this.props.dispatch(bookingActions.countDispatch({type:["loan"],status:["dispatch","booked"],deliveryDateFiter:this.state.today}));
        this.props.dispatch(returnBookingActions.countByDate({type:["loan"],status:["dispatch","close"],returnDateFiter:this.state.today}));
        this.props.dispatch(collectionBookingActions.countByDate({type:["loan"],status:["dispatch"],collectionDateFilter:this.state.today}));
    }
    setRequestCount(count)
    {
        this.setState({
            requestCount:count
        });
    }

    setDispatchCount(count)
    {
      
        this.setState({
            dispatchCount:count
        });
    }

    setReturnCount(count)
    {
      
        this.setState({
            returnCount:count
        });
    }

    setCollectionCount(count)
    {
      
        this.setState({
            collectionCount:count
        });
    }

    

    componentDidMount(){

        this.getCount();
          
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if(nextProps.requestCount !== undefined) {
            this.setRequestCount(nextProps.requestCount);
        }
        if(nextProps.dispatchCount !== undefined) {
            this.setDispatchCount(nextProps.dispatchCount);
        }
        if(nextProps.countInfo !== undefined) {
            this.setRequestCount(nextProps.countInfo);
        }
        if(nextProps.countDispatch !== undefined) {
            this.setDispatchCount(nextProps.countDispatch);
        }

        if(nextProps.countReturn !== undefined) {
           
            this.setReturnCount(nextProps.countReturn);
        }

        if(nextProps.returnCount !== undefined) {
           
            this.setReturnCount(nextProps.returnCount);
        }

        if(nextProps.countCollection !== undefined) {
           
            this.setCollectionCount(nextProps.countCollection);
        }

        if(nextProps.collectionCount !== undefined) {
           
            this.setCollectionCount(nextProps.collectionCount);
        }

        

        
    }

    render() {
      
        //const { username, password} = this.state;
        const { activePage } = this.props;
        return (
            <div>
                <div className="list-group mt-4 pr-4">
                    {/*<Link to="/admin/dashboard/" className="list-group-item d-flex justify-content-between align-items-center active">
                        Dispatch
                        <span className="badge badge-primary badge-pill">14</span>        
                    </Link>
                    <a href="dashboard-return.php" className="list-group-item d-flex justify-content-between align-items-center">
                    Returns
                        <span className="badge badge-primary badge-pill">2</span>
                    </a>
                    <a href="dashboard-collections.php" className="list-group-item d-flex justify-content-between align-items-center">
                    Collections
                        <span className="badge badge-primary badge-pill">2</span>
                    </a> */}
                    <Link to="/admin/dashboard/booking-dispatch" className={"list-group-item d-flex justify-content-between align-items-center "+(activePage=="dispatch"?"active ":"") }>
                    Dispatch  
                        <span className="badge badge-primary badge-pill">
                            {this.state.dispatchCount!="" && this.state.dispatchCount>0 &&
                                this.state.dispatchCount
                            }
                        </span>
                    </Link>

                    <Link to="/admin/dashboard/booking-returns" className={"list-group-item d-flex justify-content-between align-items-center "+(activePage=="return"?"active ":"") }>
                    Returns
                        <span className="badge badge-primary badge-pill">
                            {this.state.returnCount!="" && this.state.returnCount>0 &&
                                this.state.returnCount
                            }
                        </span>
                    </Link>
                    <Link to="/admin/dashboard/booking-collections" className={"list-group-item d-flex justify-content-between align-items-center "+(activePage=="collection"?"active ":"") }>
                    Collections
                        <span className="badge badge-primary badge-pill">
                            {this.state.collectionCount!="" && this.state.collectionCount>0 &&
                                this.state.collectionCount
                            }
                        </span>
                    </Link>

                    
                    <Link to="/admin/dashboard/booking-request" className={"list-group-item d-flex justify-content-between align-items-center "+(activePage=="request"?"active ":"") }>
                    Booking Requests
                        <span className="badge badge-primary badge-pill">
                            {this.state.requestCount!="" && this.state.requestCount>0 &&
                                this.state.requestCount
                            }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { countInfo,countDispatch } = state.rootReducer.bookings;
    const countReturn = state.rootReducer.returnBookings.returnCount;
    const countCollection = state.rootReducer.collectionBookings.collectionCount;
    return {
        countInfo,
        countDispatch,
        countReturn,
        countCollection
    };
}

export default connect(mapStateToProps)(DashboardSidebar);