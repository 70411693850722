import { authHeader } from "../helpers";
import { APIURL } from "../constants/config";

export const surgeonService = {
    add,
    getList,
    getById,
    update,
    delete: _delete,
    getSurgeonsList
};

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"surgeons/getSurgeonList", requestOptions).then(handleResponse)
        .then(kitsList => {
            return kitsList;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(APIURL+`surgeons/editSurgeon/${id}`, requestOptions).then(handleResponse);
}

function add(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(kitInfo)
    };

    return fetch(APIURL+"surgeons/saveSurgeon", requestOptions).then(handleResponse);
}

function update(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(kitInfo)
    };

    return fetch(APIURL+"surgeons/updateSurgeon", requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id)
    };

    return fetch(APIURL+"surgeons/deleteSurgeon", requestOptions).then(handleResponse);
}

function getSurgeonsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"surgeons/getAllSurgeon", requestOptions).then(handleResponse)
        .then(kitsList => {
            return kitsList;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}