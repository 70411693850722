import { bookingConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function bookings(state = initialState, action) {
    switch (action.type) {
    case bookingConstants.GETALLHOSPITALS_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.GETALLHOSPITALS_SUCCESS:
        return {
            loading: false,
            hospitalsList: action.hospitalsList.data
        };
    case bookingConstants.GETALLHOSPITALS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case bookingConstants.GETALLREPS_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.GETALLREPS_SUCCESS:
        return {
            loading: false,
            repsList: action.repsList.data
        };
    case bookingConstants.GETALLREPS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case bookingConstants.GETKITSETBYKIT_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.GETKITSETBYKIT_SUCCESS:
        return {
            loading: false,
            kitSetList: action.kitSetList.data
        };
    case bookingConstants.GETKITSETBYKIT_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case bookingConstants.GETALLBOOKINGS_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.GETALLBOOKINGS_SUCCESS:
        return {
            loading: false,
            bookingList: action.bookingList.data,
            activePage: action.bookingList.page,
            totalItemsCount: action.bookingList.total_count,
            limit: action.bookingList.page_count
        };
    case bookingConstants.GETALLBOOKINGS_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGADD_REQUEST:
        return {
            loading: true,
        };
    case bookingConstants.BOOKINGADD_SUCCESS:
        return {
            loading: false,
            redirect:true
        };
    case bookingConstants.BOOKINGADD_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGEDIT_REQUEST:
        return {
            loading: true,          
        };
    case bookingConstants.BOOKINGEDIT_SUCCESS:
        return {
            loading: false,
            bookingInfo: action.bookingInfo.data,
        };
    case bookingConstants.BOOKINGEDIT_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.GETHOSPITALADDRESS_REQUEST:
        return {
            loading: true,          
        };
    case bookingConstants.GETHOSPITALADDRESS_SUCCESS:
        return {
            loading: false,
            hospitalDetails: action.hospitalDetails.data,
        };
    case bookingConstants.GETHOSPITALADDRESS_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGUPDATE_REQUEST:
        return {
            loading: true,
        };
    case bookingConstants.BOOKINGUPDATE_SUCCESS:
        return {
            loading: false,
            redirect:true
        };
    case bookingConstants.BOOKINGUPDATE_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
        
    case bookingConstants.BOOKINGSTATUSUPDATE_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.BOOKINGSTATUSUPDATE_SUCCESS:
        return {
            loading: false,
            redirect:true
        };
    case bookingConstants.BOOKINGSTATUSUPDATE_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.SETRETURN_REQUEST:
        return {
            loading: true,
            returnModal: false
        };
    case bookingConstants.SETRETURN_SUCCESS:
        return {
            loading: false,
            returnModal: true,
            returnRefresh: true
        };
    case bookingConstants.SETRETURN_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGCOUNT_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.BOOKINGCOUNT_SUCCESS:
        return {
            loading: false,
            countInfo: action.countInfo.data,
        };
    case bookingConstants.BOOKINGCOUNT_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGDISPATCH_COUNT_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.BOOKINGDISPATCH_COUNT_SUCCESS:
        return {
            loading: false,
            countDispatch: action.countDispatch.data,
        };
    case bookingConstants.BOOKINGDISPATCH_COUNT_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGSAVEDISPATCH_REQUEST:
        return {
            loading: true,
            returnModal: false
        };
    case bookingConstants.BOOKINGSAVEDISPATCH_SUCCESS:
        return {
            loading: false,
            refreshList:true,
        };
    case bookingConstants.BOOKINGSAVEDISPATCH_FAILURE:
        return { 
            error: action.error,
            loading: false
        };
    case bookingConstants.BOOKINGLOG_REQUEST:
        return {
            loading: true
        };
    case bookingConstants.BOOKINGLOG_SUCCESS:
        return {
            loading: false,
            logList:action.logList.data,
        };
    case bookingConstants.BOOKINLOG_FAILURE:
        return { 
            error: action.error,
            loading: false
        };

    case bookingConstants.BOOKINGSAVECOLLECTION_REQUEST:
        return {
            loading: true,
            collectionModal: false
        };
    case bookingConstants.BOOKINGSAVECOLLECTION_SUCCESS:
        return {
            loading: false,
            collectionList:true,
        };
    case bookingConstants.BOOKINGSAVECOLLECTION_FAILURE:
        return { 
            error: action.error,
            loading: false
        };

    case bookingConstants.BOOKINGUPDATEDATE_REQUEST:
        return {
            loading: true,
            bookingList: state.bookingList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case bookingConstants.BOOKINGUPDATEDATE_SUCCESS:
        return {
            loading: false,
            redirect: true,
        };
    case bookingConstants.BOOKINGUPDATEDATE_FAILURE:
        return { 
            error: action.error,
            bookingList: state.bookingList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit,
            loading: false
        };
    case bookingConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
            editModal:true,
            modal:false,
            loading: true
        };
    case bookingConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
            deleteModal:false,
            refreshList:true,
            editModal:false,
            modal:false,
            loading: false
        };
    case bookingConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        return {
            ...state,
            loading: false,
            kitsList: state.kitsList.map(lst => {
                if (lst._id === action.id) {
                    // make copy of user without 'deleting:true' property
                    // const { deleting, ...userCopy } = user;
                    const { ...lstCopy } = lst;
                    // return copy of user with 'deleteError:[error]' property
                    return { ...lstCopy, deleteError: action.error };
                }

                return lst;
            })
        };
    default:
        return state;
    }
}