import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import KitNavbar from "../Kits/KitNavbar";
import { Redirect } from "react-router-dom";
import { productActions } from "../../../../actions";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            editModal: false,
            deleteModal:false,
            name: "",
            code: "",
            id: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            error: false,
        };

        this.getProductsList = this.getProductsList.bind(this);
        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editModalOpen = this.editModalOpen.bind(this);
        this.editModalClose = this.editModalClose.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getProductsList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {

        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getProductsList() {
        this.props.dispatch(productActions.getProductsList({search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getProductsList();});
        
    }

    addNewModalOpen() {
        this.setState({ addNewModal: !this.state.addNewModal});
    }

    addNewModalClose() {
        this.setState({ 
            addNewModal: false,
            name: "",
            code: ""
        });
    }

    codeHandler(val) {
        this.setState({
            code: val
        });
    }

    nameHandler(val) {
        this.setState({
            name: val
        });
    }

    handleSubmit() {
        this.props.dispatch(productActions.addProduct({name: this.state.name, code: this.state.code}));
    }

    getSingleProduct(id) {
        this.props.dispatch(productActions.getById(id));
    }

    setEditProduct(data) {
        this.setState({
            code: data.code,
            name: data.name,
            id: data._id
        });
        this.editModalOpen();
    }
   
    editModalOpen() {
        this.setState({ editModal: !this.state.editModal});
    }

    editModalClose() {
        this.setState({ 
            editModal: false,
            name: "",
            code: ""
        });
    }

    handleUpdate() {
        this.props.dispatch(productActions.updateProduct({name: this.state.name, code: this.state.code, id:this.state.id}));
    }

    deleteModalOpen() {
        this.setState({ deleteModal: true});
    }

    deleteModalClose() {
        this.setState({ 
            deleteModal: false,
            name: "",
            code: ""
        });
    }

    handleDelete(id) {
        this.setState({ 
            id: id
        });
        this.deleteModalOpen();
    }

    doDelete() {
        this.props.dispatch(productActions.delete({id:this.state.id}));
    }    

    componentDidMount(){
        this.getProductsList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.refreshList == true) {
            this.setState({ 
                activePage: 1
            },()=>{this.getProductsList();});
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        if(nextProps.modal == false)
        {
            this.addNewModalClose();
        }

        if(nextProps.editModal == true)
        {
            this.setEditProduct(nextProps.product);
        }

        if(nextProps.editModal == false)
        {
            this.editModalClose();
        }

        if(nextProps.deleteModal == false)
        {
            this.deleteModalClose();
        }
        
        if(nextProps.error !== "")
        {
            this.setState({ 
                error: true 
            });

            
        }        
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        //const { loggingIn } = this.props;
        const { productsList, product, error, loading} = this.props;
        return (
            <div>
                <Navbar activePage="kits"/>
                <div className="container">
                    <div className="pt-4 row align-items-center">
                        <div className="col-md-4">
                            <div className="sub-menu">
                                <KitNavbar activePage="products"/>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <form className="form-search">
                                <Input 
                                    className="form-control mr-sm-2" 
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />            
                            </form>
                        </div>  
                        <div className="col-md-3 text-right">
                            <button className="btn btn-primary add_option" onClick={this.addNewModalOpen}><i className="fa fa-plus"></i> <span>Add New</span></button>
                        </div>
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>

                                    <th scope="col" width="200">Item Code</th>
                                    <th scope="col">Item Name</th>           
                                    <th scope="col" className="text-right" width="100">Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {   productsList && productsList.map((item) => (
                                    <tr key={item._id}>
                                        <td width="200">
                                            <div className="">                   
                                                <span><strong>{item.code}</strong></span>
                                            </div>
                                        </td>
                                        <td>
                                            {item.name}
                                        </td>
                            
                            
                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="dots">...</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">                       
                                                    <a className="dropdown-item" onClick={() => this.getSingleProduct(item._id)} href="javascript:;">Edit</a>
                                                    <a className="dropdown-item" onClick={() => this.handleDelete(item._id)}  href="javascript:;">Delete</a>                                
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                { productsList && productsList.length == 0 && loading == false && 
                                <tr className="text-center">
                                    <td colSpan="3">No Record Found</td>
                                </tr>
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>

                {productsList && productsList.length >0 &&
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Previous"
                    />
                }

                <Modal size="md" isOpen={this.state.addNewModal} toggle={() => this.addNewModalClose()}>
                    <ModalHeader className="" toggle={() => this.addNewModalClose()}>
                        Add New Item
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group">
                                <label>Item Code</label>
                                <Input 
                                    type="text"
                                    className="form-control"
                                    value={this.state.code} 
                                    onChange = {(e) => this.codeHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.code ? error.code : ""}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Item Name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.name}
                                    onChange = {(e) => this.nameHandler(e.target.value)}
                                />
                                {(this.state.error && error) ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                            </div>                
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.addNewModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}

                <Modal size="md" isOpen={this.state.editModal} toggle={() => this.editModalClose()}>
                    <ModalHeader className="" toggle={() => this.editModalClose()}>
                        Edit Item
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            {product &&
                                <div>
                                    <div className="form-group">
                                        <label>Item Code</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.code} 
                                            onChange = {(e) => this.codeHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.code ? error.code : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Item Name</label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.name} 
                                            onChange = {(e) => this.nameHandler(e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.name ? error.name : ""}</span> : ""}
                                        {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                                    </div>
                                </div>  
                            }            
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.editModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                
                <Modal size="md" isOpen={this.state.deleteModal} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Item
                    </ModalHeader>
                    <ModalBody>
                        {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                        <div className="my-2">Please confirm if you want to delete this item?</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false &&
                                <button className="btn btn-primary" onClick={this.doDelete}>Ok</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Delete confirmation */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { productsList, activePage, totalItemsCount, limit } = state.rootReducer.products;
    const { product, error, modal, editModal, loading, refreshList, deleteModal} = state.rootReducer.products;

    return {
        productsList,
        product,
        error,
        modal,
        editModal,
        loading,
        refreshList,
        deleteModal,
        activePage, 
        totalItemsCount, 
        limit
    };
}

export default connect(mapStateToProps)(Index);