//export const APIURL = "http://devapi-lavender.imenso.in:5001/";
export const APIURL = "https://api-lavender.imenso.in/";
export const BASEURL = "https://lavender.imenso.in/";

//export const APIURL = "https://api.nice-colden.92-205-107-153.plesk.page:5005/";
//export const BASEURL = "https://nice-colden.92-205-107-153.plesk.page/";

//export const APIURL = "https://devapi-lavender.imenso.in/";
//export const BASEURL = "https://devlavender.imenso.in/";

