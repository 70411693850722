import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Button} from "reactstrap";
import Select from "react-select";
import dateFormat from "dateformat";

import Navbar from "../Navbar";
import { availabilityActions, kitActions, bookingActions } from "../../../../actions";
import ChartHeader from "./Head";
import ChartList from "./List";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";


const datess = new Date();
const separator="-";
var from = new Date(
    datess.getFullYear(),
    datess.getMonth(),
    datess.getDate() -3, // Will increase month if over range
);
from =`${from.getFullYear()}${separator}${from.getMonth()+1<10?`0${from.getMonth()+1}`:`${from.getMonth()+1}`}${separator}${from.getDate()<10?`0${from.getDate()}`:`${from.getDate()}`}`;
var to = new Date(
    datess.getFullYear(),
    datess.getMonth(),
    datess.getDate() +40, // Will increase month if over range
);
to =`${to.getFullYear()}${separator}${to.getMonth()+1<10?`0${to.getMonth()+1}`:`${to.getMonth()+1}`}${separator}${to.getDate()<10?`0${to.getDate()}`:`${to.getDate()}`}`;

class Index extends Component {
    constructor(props) {
        super(props);
        this.scrollLeft = React.createRef();
        this.tableMain = React.createRef();
        // reset login status 

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            from:from,
            to:to,
            dates:[],
            kitListFlag:0,
            kits:[],
            selectedKit:[],
            bookingStatus:[{label:"All Status",value:""},{label:"Booked",value:"Booked"},{label:"Double Booked",value:"Double Booked"}], 
            selectedBookingStatus:[{label:"All Status",value:""}],
            users:[],
            selectedUser:[{label:"Booked By",value:""}],
            hospitals:[],
            selectedHospital:[{label:"All Hospital",value:""}],
            bookingDetailModal:false,
            scrollCheck:0,
            scrollStatus:true,
            bookingTypes:[{label:"Loan",value:"loan"},{label:"Consignment",value:"consignment"}],
            selectedBookingType:[{label:"Loan",value:"loan"}],
            bookingInfo: {
                id: "",
                hospitalId: "",
                hospital_address: "",
                po_number: "",
                operation_name: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                delivery_date:"",
                delivery_date_slot:"",
                operation_date:"",
                operation_date_slot:"",
                collection_date:"",
                return_date:"",
                operationName:"",
                hospitalName:"",
                repName:"",
                repEmail:"",
                booking_type:"",
                hospitalContactPerson:"",
                hospitalContactPersonEmail:"",
                hospitalContactPersonPhone:"",
            },
            kitWithKitSet:[]
        };

        this.getAvailability = this.getAvailability.bind(this);
        
        this.handlePageChange = this.handlePageChange.bind(this);
        this.leftScroll=this.leftScroll.bind(this);
        this.rightScroll=this.rightScroll.bind(this);
        this.bookingDetailHandle=this.bookingDetailHandle.bind(this);
        this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
        this.setSelectedOptionBookingStatus = this.setSelectedOptionBookingStatus.bind(this);
        this.setSelectedOptionUser = this.setSelectedOptionUser.bind(this);
        this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
        this.setSelectedOptionBookingType = this.setSelectedOptionBookingType.bind(this);

    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {

        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getAvailability() {  
        let selectedKit=this.state.selectedKit;
        let selectdKit="";

        selectedKit.map((task)=>{
            if(selectdKit!="")
            {
                selectdKit+=",";
            }
            selectdKit+=task.value;
        });

        console.log("multiple ",this.state.selectedKit,selectdKit);
        //this.state.selectedKit[0].value
        this.props.dispatch(availabilityActions.getAvailability({bookingTypeFilter:[this.state.selectedBookingType[0].value],search: this.state.search,kitFilter:selectdKit,bookedByFilter:this.state.selectedUser[0].value,hospitalFilter:this.state.selectedHospital[0].value}));
    }

    getDropdown(){
        this.props.dispatch(kitActions.kitListDropdown({search: ""}));
        this.props.dispatch(bookingActions.getHospitalsList({search: ""}));
        this.props.dispatch(bookingActions.getRepsList({search: ""}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getAvailability();});
        
    }

    getDatesBetween = (startDate, endDate) => {
        const dates = []; 
        var date=""; 
        var holiday=""; 
        var current=""; 
        var classN="";
        var today = new Date();
        today = today.getFullYear() +"-"+ today.getMonth() +"-"+ today.getDate();
        var oldYearMonth="";
        var newYearMonth="";
        var colSpan=0;
        var months=[];
        let separator="-";
        // Strip hours minutes seconds etc.
        let currentDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );

        while (currentDate <= endDate) {

            newYearMonth=currentDate.getFullYear()+"-"+currentDate.getMonth();
          
            if(oldYearMonth!==newYearMonth)
            {
                oldYearMonth=newYearMonth;
               
               
                months.push({"colSpan":colSpan,"month":currentDate.getMonth()+1,"year":currentDate.getFullYear()});
                if(months.length>1){
                    months[months.length-2]["colSpan"]=colSpan;
                }
                colSpan=0;
                

               
            }
            colSpan++;

            holiday=""; current=""; classN="";
            date=currentDate.getFullYear()+"-"+currentDate.getMonth()+"-"+currentDate.getDate();
            
            if(currentDate.getDay() == 6 || currentDate.getDay() == 0)
            {
                holiday="Yes";
                classN="holiday";
            }

            if(today==date)
            {
                current="Yes";
                classN="tdy";
            }


            date =`${currentDate.getFullYear()}${separator}${currentDate.getMonth()+1<10?`0${currentDate.getMonth()+1}`:`${currentDate.getMonth()+1}`}${separator}${currentDate.getDate()<10?`0${currentDate.getDate()}`:`${currentDate.getDate()}`}`;

           
            dates.push({"classN":classN,"weekday":currentDate.getDay(),"holiday":holiday,"current":current,"fulldate":date,"day":currentDate.getDate(),"month":currentDate.getMonth()+1,"year":currentDate.getFullYear()});

            currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + 1, // Will increase month if over range
            );
        }

        if(months.length>1)
        {
            months[months.length-1]["colSpan"]=colSpan;
        }

        this.setState({
            dates: dates,
            months
        });

    };

    leftScroll(){
     
        var from = new Date(this.state.from);
        var to =new Date(this.state.to);


        from=new Date(
            from.getFullYear(),
            from.getMonth(),
            from.getDate() -10, // Will increase month if over range
        );

        to=new Date(
            to.getFullYear(),
            to.getMonth(),
            to.getDate() , // Will increase month if over range
        );
        this.setState({
            from: from,
            to
        });
        this.getDatesBetween(from,to);

        
    }

    rightScroll(){
        var from = new Date(this.state.from);
        var to =new Date(this.state.to);


        from=new Date(
            from.getFullYear(),
            from.getMonth(),
            from.getDate() , // Will increase month if over range
        );

        to=new Date(
            to.getFullYear(),
            to.getMonth(),
            to.getDate() +10, // Will increase month if over range
        );
        this.setState({
            from: from,
            to
        });
        this.getDatesBetween(from,to);

        
    }

    setSelectedOptionKit = selectedOptionKit => {
        //var selectedKit=[{value:e.value, label:e.label}];
        this.setState({
            selectedKit:selectedOptionKit
        },()=>{this.getAvailability();});
    }; 

    setSelectedOptionBookingStatus(e){
        var selectedBookingStatus=[{value:e.value, label:e.label}];
        this.setState({
            selectedBookingStatus
        });
    } 

    setSelectedOptionUser(e){
        var selectedUser=[{value:e.value, label:e.label}];
        this.setState({
            selectedUser
        },()=>{this.getAvailability();});
    } 

    setSelectedOptionHospital(e){
        var selectedHospital=[{value:e.value, label:e.label}];
        this.setState({
            selectedHospital
        },()=>{this.getAvailability();});
    } 

    setSelectedOptionBookingType(e){
        var selectedBookingType=[{value:e.value, label:e.label}];
        this.setState({
            selectedBookingType
        },()=>{this.getAvailability();});
    } 

    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            kitListFlag:1
        });
    }

    showHospitalsList(hospitalsList) {
        var tempHospitals = [{label:"All Hospital",value:""}];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        this.setState({
            hospitals: tempHospitals,
        });
    }

    showRepsList(repsList) {
        var tempRepsList = [{label:"Booked By",value:""}];
        repsList.map((item)=> {
            var option = {value: item._id, label: item.first_name+" "+item.last_name};
            tempRepsList.push(option);
        });
        this.setState({
            users: tempRepsList
        });
    }

    bookingDetailModalClose(){
        this.setState({
            bookingDetailModal: false
        });
    }

    bookingDetailHandle(id){
        this.props.dispatch(bookingActions.getById(id));
       
    }

    getHospitalAddressList(id) {
        this.props.dispatch(bookingActions.getHospitalDetailsById(id));
    }

    scrollss(){

        const { scrollCheck, scrollStatus } = this.state;
        console.log(document.getElementById("main-table").offsetTop);
        
        if(scrollStatus==true)
        {
       
            this.setState({
                scrollCheck:document.getElementById("main-div").scrollLeft
            });

            if(scrollCheck>document.getElementById("main-div").scrollLeft)
            {
                
                if(document.getElementById("main-div").scrollLeft<20)
                {
                    this.leftScroll(); 
                    
                    setTimeout(function(){
                        if(document.getElementById("main-div").scrollLeft==0)
                        {
                            document.getElementById("main-div").scrollLeft =100; 
                            console.log("after left", document.getElementById("main-div").scrollLeft );
                        }
                        
                    },1000);
                   
                    console.log("left");
                   
                  

                  
                }
            }
            else
            {

                
                if(this.tableMain.current.offsetWidth-this.scrollLeft.current.offsetWidth < document.getElementById("main-div").scrollLeft+50)
                {
                    this.rightScroll(); 
                    document.getElementById("main-div").scrollLeft -=100;
                    

                   
                }
                console.log("right");
               
            }
        }

        

        //console.log(document.getElementById("main-div").scrollLeft,this.scrollLeft.current.offsetWidth,this.tableMain.current.offsetWidth,e);
    }


    setBookingData(data) {
        var  hospitalName="";
        var defaultRepsLable="";
        var defaultHospital = this.state.hospitals.find(val => val.value === data.hospitalId);
        if(defaultHospital)
        {
            hospitalName=defaultHospital.label;
        }

        var defaultReps = this.state.users.find(val => val.value === data.assigned_rep);

        if(defaultReps)
        {
            defaultRepsLable=defaultReps.label;
        }
      
        const { bookingInfo } =this.state;
        bookingInfo["id"]=data["_id"];
        bookingInfo["operation_name"]=data["operation_name"];
        bookingInfo["hospitalId"]=data["hospitalId"];
        bookingInfo["hospital_address"]=data["hospital_address"];
        bookingInfo["operation_date_slot"]=data["operation_date_slot"];
        bookingInfo["po_number"]=data["po_number"];
        bookingInfo["shipping_charge"]=data["shipping_charge"];
        bookingInfo["delivery_date_slot"]=data["delivery_date_slot"];
        bookingInfo["assigned_rep"]=data["assigned_rep"];
        bookingInfo["delivery_date"]=dateFormat(data["delivery_date"], "yyyy-mm-dd");
        bookingInfo["operation_date"]=dateFormat(data["operation_date"], "yyyy-mm-dd");
        bookingInfo["collection_date"]=dateFormat(data["collection_date"], "yyyy-mm-dd");
        bookingInfo["additional_notes"]=data["additional_notes"];
        bookingInfo["hospitalName"]=hospitalName;
        bookingInfo["booking_type"]=data["booking_type"];
        bookingInfo["status"]=data["status"];
        bookingInfo["repName"]=defaultRepsLable;


        this.setState({
            bookingInfo,
            bookingDetailModal:true,
            kitWithKitSet:[]
        }, () => {
            this.getHospitalAddressList(data.hospitalId);
        });


        data.bookingKitId.map((item)=> {
            this.props.dispatch(bookingActions.getKitSetByKit({kitId: item.kitId}));
        });
    }

    showAddressList(address) {
        const { bookingInfo } = this.state;
        bookingInfo["hospitalContactPerson"]=address.contact_person_name;
        bookingInfo["hospitalContactPersonEmail"]=address.email;
        bookingInfo["hospitalContactPersonPhone"]=address.contact_person_phone_number;
      
    }

    showKitsSetList(data){
        const {  bookingInfo,kitWithKitSet } =this.state;
        var subKitSet=[]; 
        var lnt=0; 
        var hire_charge="";
       
        data[0]["kitsets"].map((kset)=>{
            hire_charge="";
            lnt=data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == bookingInfo.id ).length;
            if(lnt>0)
            {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == bookingInfo.id ).map((result)=>{
                    hire_charge=result.hire_charge;
                });
               
            }
            subKitSet.push({set_name:kset.set_name,_id:kset._id,hire_charge:hire_charge,added:lnt});

        });


        kitWithKitSet.push({name:data[0].name,_id:data[0]._id,sets:subKitSet});

       
        this.setState({
            kitWithKitSet
        });
 
    }

    handleToScroll(){
        console.log("ss");
    }

    clearAllHndle()
    {

        this.setState({
            selectedKit:[],
            selectedHospital:[{label:"All Hospital",value:""}],
            selectedBookingStatus:[{label:"All Status",value:""}],
            selectedBookingType:[{label:"Loan",value:"loan"}],
            selectedUser:[{label:"Booked By",value:""}],
            search:""
        },()=>{this.getAvailability();});

    }


    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleToScroll);
    }

  
    componentDidMount(){
        this.getAvailability(); this.getDropdown();
        const d1 = new Date(this.state.from);
        const d2 = new Date(this.state.to);

        this.getDatesBetween(d1,d2);
        setTimeout(function(){
            document.getElementById("main-div").scrollLeft =10; 
        },300);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.refreshList==true)
        {
            this.setState({ 
                activePage: 1
            },()=>{this.getAvailability();});
            
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        if(nextProps.kitListDropdown !== undefined && this.state.kitListFlag==0) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if(nextProps.hospitalsList !== undefined) {
            this.showHospitalsList(nextProps.hospitalsList);
        }

        if(nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if(nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if(nextProps.hospitalDetails !== undefined) {
            this.showAddressList(nextProps.hospitalDetails);
        }

        if(nextProps.kitSetList !== undefined ) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }

      
        
        return (
            <div>
                <Navbar activePage="availability"/>
                <div className="avail-chart overflow-hidden">
                    <div className="ac-header">
                        <div className="d-flex align-items-center justify-content-between">

                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h1 className="heading">Kit Availability</h1>
                                </div>
                                <div className="">
                                    <form className="form-search mx-4">
                                        <Input 
                                            className="form-control mr-sm-2" 
                                            type="text"
                                            placeholder="Search.."
                                            value={this.state.search}
                                            onChange={(e) => this.handleSearch(e.target.value)}
                                        />                
                                    </form>
                                </div>
                            </div>  
                            <div className="text-right av-filter">         
                                <div className="btn-group">
                                    <Select
                                        value={this.state.selectedBookingType}
                                        onChange={this.setSelectedOptionBookingType}
                                        options={this.state.bookingTypes}
                                        className="small mw_100 text-left"
                                    />
                                </div>   
                                <div className="btn-group m-btn-group">
                                    {/*<Select
                                        value={this.state.selectedKit}
                                        onChange={this.setSelectedOptionKit}
                                        options={this.state.kits}
                                        className="small mw_100 text-left"
                                        isMulti={true}
                                        placeholder="All Kits"
                                    />*/}
                                    <ReactMultiSelectCheckboxes  
                                        placeholderButtonLabel="All Kits"
                                        value={this.state.selectedKit}
                                        onChange={this.setSelectedOptionKit} 
                                        className="small mw_100 text-left" 
                                        options={this.state.kits} 
                                        placeholder="All Kits"
                                    />

                                </div>
                                <div className="btn-group">
                                    <Select
                                        value={this.state.selectedBookingStatus}
                                        onChange={this.setSelectedOptionBookingStatus}
                                        options={this.state.bookingStatus}
                                        className="small mw_100 text-left"
                                    />
                                </div>
                                <div className="btn-group">
                                    <Select 
                                        value={this.state.selectedUser}
                                        onChange={this.setSelectedOptionUser}
                                        options={this.state.users}
                                        className="small mw_100 text-left"
                                    />
                                </div>
                                <div className="btn-group">
                                    <Select 
                                        value={this.state.selectedHospital}
                                        onChange={this.setSelectedOptionHospital}
                                        options={this.state.hospitals}
                                        className="small mw_100 text-left"
                                    />
                                </div>
                                
                            </div>
                            <div className="btn-group">
                                <Button color="link" onClick={()=>this.clearAllHndle()}>Clear All</Button>
                            </div>
                        </div>                        
                    </div>
                    <div className="avail-chart">   
                        <div  className="scroller table-chart " id="main-div" ref={this.scrollLeft} onScroll={()=>this.scrollss()}>
                            <table id="main-table" className="main-table" ref={this.tableMain}>
                                <ChartHeader 
                                    dates={this.state.dates} 
                                    months={this.state.months}
                                    leftScroll={this.leftScroll}
                                    rightScroll={this.rightScroll}

                                />
                                <ChartList 
                                    dates={this.state.dates} 
                                    bookingDetailHandle={this.bookingDetailHandle}
                                    statusFilter={this.state.selectedBookingStatus[0].value}
                                />   
                            </table>
                        </div>
                    </div>
                </div>

                <Modal size="md" isOpen={this.state.bookingDetailModal} toggle={() => this.bookingDetailModalClose()}>
                    <ModalHeader className="" toggle={() => this.bookingDetailModalClose()}>
                        Booking Details 
                        { this.state.bookingInfo.status=="booked" &&
                            <Link  className="text-muted ml-2 small" to={"/admin/booking/edit/"+this.state.bookingInfo.id}><i className="fa fa-pencil" /></Link>
                        }
                    </ModalHeader>
                    <ModalBody>
                        <h4 className="lined-h">Hospital Details </h4>
                        <h5>{this.state.bookingInfo.hospitalName}</h5>
                        <div className="form-group">
                            <div className="info">
                                <div className="info-left">
                                    <strong>{this.state.bookingInfo.repName}</strong>
                                    <div><small>Location: {this.state.bookingInfo.hospital_address}</small></div>
                                    <div><small>Email: {this.state.bookingInfo.hospitalContactPersonEmail}</small></div>
                                    <div><small>Phone: {this.state.bookingInfo.hospitalContactPersonPhone}</small></div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <span>Operation Name: <strong>{this.state.bookingInfo.operation_name}</strong> </span>                    
                        </div>
                        <div className="form-group">
                            <span>PO Number: <strong>{this.state.bookingInfo.po_number}</strong> </span>
                        </div>

                        <h4 className="lined-h mt-5">Important Dates</h4>
                        {this.state.bookingInfo.booking_type!="loan" && 
                            <div className="form-group">
                                <label>Delivery date</label>
                                <div><strong>{dateFormat(this.state.bookingInfo.delivery_date, "mmmm dS, yyyy")} </strong></div>
                            </div>
                        }
                        {this.state.bookingInfo.booking_type=="loan" && 
                            <div className="form-group">
                                <label>Delivery date</label>
                                <div><strong>{dateFormat(this.state.bookingInfo.delivery_date, "mmmm dS, yyyy")}  {this.state.bookingInfo.delivery_date_slot}</strong></div>
                            </div>
                        }
                        {this.state.bookingInfo.booking_type=="loan" && 
                            <div className="form-group">
                                <label>Operation date</label>
                                <div><strong>{dateFormat(this.state.bookingInfo.operation_date, "mmmm dS, yyyy")} {this.state.bookingInfo.operation_date_slot}</strong></div>
                            </div>
                        }

                        <div className="form-group">
                            <label>Collection date</label>
                            <div><strong>{dateFormat(this.state.bookingInfo.collection_date, "mmmm dS, yyyy")} AM</strong></div>
                        </div> 


                        <h4 className="mt-5 lined-h">Purchase/Hire Details</h4> 
                        <table className="table">
                            <tbody>
                                {this.state.kitWithKitSet && this.state.kitWithKitSet.map((kit,index)=>(
                                    <React.Fragment  key={index}>
                                        <tr>
                                            <th colSpan="2">{kit.name}</th>
                                        </tr>
                                        {kit.sets.map((setd,ind)=>(
                                            <React.Fragment  key={ind}>
                                                {setd.added==1  &&
                                                    <tr>
                                                        <td>
                                                            <div className="mb-2">
                                                                <a href="javascript:;" data-toggle="modal" data-target="#kitDetails">{setd.set_name}</a>
                                                            </div>
                                                                                
                                                        </td>
                                                        <td>
                                                            
                                                            <div>{setd.hire_charge}</div>
                                                            
                                                        </td>
                                                     
                                                      
                                                    </tr>
                                                }
                                            </React.Fragment>

                                        ))}
                                    </React.Fragment>

                                ))}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary" onClick={() =>this.bookingDetailModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingInfo,hospitalDetails,hospitalsList, repsList, kitSetList } = state.rootReducer.bookings;
    const { error, refreshList, loading } = state.rootReducer.availabilitys;
    const  { kitListDropdown } = state.rootReducer.kits;
    return {
        error,
        refreshList,
        loading,
        kitListDropdown,
        hospitalsList,
        repsList,
        bookingInfo,
        hospitalDetails,
        kitSetList
       
    };
}

export default connect(mapStateToProps)(Index);