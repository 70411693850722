import { authHeader } from "../helpers";
import { APIURL } from "../constants/config";

export const kitService = {
    addKit,
    getKitsList,
    kitListDropdown,
    getById,
    updateKitStatus,
    update,
    delete: _delete
};



function getKitsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"kits/getKits", requestOptions).then(handleResponse)
        .then(kitsList => {
            return kitsList;
        });
}

function kitListDropdown(search) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(APIURL+"kits/kitListForDropdown", requestOptions).then(handleResponse)
        .then(kitListDropdown => {
            return kitListDropdown;
        });
}




function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(APIURL+`kits/getKit/${id}`, requestOptions).then(handleResponse);
}

function addKit(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(kitInfo)
    };

    return fetch(APIURL+"kits/saveKits", requestOptions).then(handleResponse);
}

function update(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(kitInfo)
    };

    return fetch(APIURL+"kits/updateKits", requestOptions).then(handleResponse);
}

function updateKitStatus(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(kitInfo)
    };

    return fetch(APIURL+"kits/updateKitStatus", requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(id)
    };

    return fetch(APIURL+"kits/deleteKits", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}