import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import { bookingActions } from "../../../../actions";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

class Return extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            updateBookingInfo: {
                id: "",
                hospitalId: "",
                hospital_address: "",
                po_number: "",
                operation_name: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date:"",
                delivery_date_slot:"",
                operation_date:"",
                operation_date_slot:"",
                collection_date:"",
                booking_type:"",
                kitData:[],
                kitSetData:[],
                return_date:""
            },
            returnData: {
                courier: "a",
                return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status:"returned",
                log_by_id:""
            },
            showDateInput: true,
            boxArray: [{box_type: "", number_of_box: ""}],
            returnKit: [],
            returnModal: this.props.returnModal,
            returnSubmitFlag:0,
            returnSetDataLength:0,
            kitWithKitSet:[]
           
        };
     
        this.getSingleBooking = this.getSingleBooking.bind(this);
     
        this.handleReturn = this.handleReturn.bind(this);
        this.returnModalClose = this.returnModalClose.bind(this);
        this.handleCourier = this.handleCourier.bind(this);
        this.handleReturnDate = this.handleReturnDate.bind(this);
        this.handleNote = this.handleNote.bind(this);
        this.submitReturnData = this.submitReturnData.bind(this);
    }

    getSingleBooking(id) {
        this.props.dispatch(bookingActions.getById(id));
    }


    handleReturn(){
        this.setState({
            returnModal: true,
            returnSubmitFlag:0,
            returnSetDataLength:0
        });
    }


    returnModalOpen(bookingId){
        this.setState({
            returnModal:true,
            bookingId:bookingId,
            returnSetDataLength:0,
            returnSubmitFlag:0,
        });
        this.getSingleBooking(bookingId);

    }
    
    returnModalClose(){
        const { kitWithKitSet } =  this.state;
     
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn =false;
                 
            });
        });


        this.setState({
            returnModal: false,
            returnSubmitFlag:0,
            returnData: {
                courier: "a",
                return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status:"returned",
                log_by_id:""
            },
            boxArray: [{box_type: "", number_of_box: ""}],
            kitWithKitSet:kitWithKitSet
        });

        this.props.returnModalClose();
    }

    returnSuccess(){
        const { kitWithKitSet } =  this.state;
     
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn =false;
                 
            });
        });


        this.setState({
            returnModal: false,
            returnData: {
                courier: "a",
                return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status:"returned",
                log_by_id:""
            },
            boxArray: [{box_type: "", number_of_box: ""}],
            kitWithKitSet:kitWithKitSet
        });

        this.props.returnSuccess();
    }

    handleCourier(val) {
        this.setState({
            returnData: {
                ...this.state.returnData,
                courier: val
            },
        });
    }

    handleReturnSet(val, outerIndex, innerIndex) {
        const { kitWithKitSet } =  this.state;
        kitWithKitSet[outerIndex].sets[innerIndex].isreturn = val;
        this.setState({
            kitWithKitSet
        }, () => {
            this.checkDateInput();
        });
    }

    checkDateInput() {
        const { kitWithKitSet } =  this.state;
        var trueCount = 0;
        var setCount = 0;
        kitWithKitSet.map((kit) => {
            
            kit.sets.map((set) => {
                if(set.added==1 && set.status!="close")
                {
                    setCount ++;
                }
                if(set.isreturn === true && set.status!="close" &&  set.added==1) {
                    trueCount++;
                }
            });
        });

        if (trueCount !== setCount) {
            this.setState({
                showDateInput: true
            });
        } else {
            this.setState({
                showDateInput: false
            }); 
        }        
    }

    handleReturnDate(val) {
        this.setState({
            returnData: {
                ...this.state.returnData,
                return_date: val
            },
        });
    }

    handleReturnBoxType(index, val) {
        const { boxArray } = this.state;
        boxArray[index].box_type = val;
        this.setState({
            boxArray
        });
    }

    handleReturnBoxNumber(index, val) {
        const { boxArray } = this.state;
        boxArray[index].number_of_box = val;
        this.setState({
            boxArray
        });
    }

    handleNote(val){
        this.setState({
            returnData: {
                ...this.state.returnData,
                additional_note: val
            },
        }); 
    }

    handleBoxAdd(e) {
        e.preventDefault();
        const { boxArray } = this.state;
        boxArray.push({box_type: "", number_of_box: ""});
        this.setState({
            boxArray
        });
    }

    deleteAddBox(e, index){
        e.preventDefault();
        const { boxArray } = this.state;
        var deleteBox = boxArray;
        deleteBox.splice(index, 1);
        this.setState({
            boxArray: deleteBox
        });
    }

    submitReturnData() {
        const { kitWithKitSet, returnData} = this.state;
        var falg=0; 
        var kitSetLength=0;
        var returnSetArray = [];
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                if(set.added==1 && set.status!="close")
                {
                    kitSetLength++;
                }
                if(set.isreturn && set.added==1 && set.status!="close") {
                    returnSetArray.push({"bookingKitSetId": set._id});
                }
            });
        });

        returnData.returnBoxesData = this.state.boxArray;
        returnData.returnSetData = returnSetArray;
        returnData.bookingId = this.state.bookingId;
        returnData.log_status="returned";
        returnData.log_by_id=this.state.user.data._id;

        this.state.boxArray.map((item)=>{
            if(item.box_type=="" || item.number_of_box=="")
            {
                falg=1;
            }

        });

        if(returnData.returnSetData.length==0)
        {
            falg=1;
        }


        if(returnData.returnSetData.length>0 && returnData.returnSetData.length!=kitSetLength && returnData.return_date=="")
        {
            falg=1;
        }
        else if(returnData.return_date!="" && returnData.returnSetData.length>0 && returnData.returnSetData.length==kitSetLength)
        {
            returnData.return_date="";
        }

        
         
        this.setState({
            returnSubmitFlag:1,
            returnSetDataLength:returnData.returnSetData.length
        });
        
        if(returnData.courier== "" || returnData.additional_note== "")
        {
            this.setState({
                returnData
            }, () => {
                this.props.dispatch(bookingActions.saveReturn(this.state.returnData));
            });
        }
        else if(falg==0)
        {
            this.setState({
                returnData
            }, () => {
                this.props.dispatch(bookingActions.saveReturn(this.state.returnData));
            });
        }
    }
   
    setBookingData(data) {
        this.setState({
            kitWithKitSet:[]
        });
        data.bookingKitId.map((item)=> {
           
            this.props.dispatch(bookingActions.getKitSetByKit({kitId: item.kitId}));
        });
    }

    showKitsSetList(data){
        const { kitWithKitSet } =this.state;
        var subKitSet=[]; 
        var lnt=0; 
        var hire_charge="";
        var status="";

        data[0]["kitsets"].map((kset)=>{
            hire_charge="";
            status="";
            lnt=data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId ).length;
            if(lnt>0)
            {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId).map((result)=>{
                    hire_charge=result.hire_charge;
                    status=result.status;
                });
               
            }
            subKitSet.push({set_name:kset.set_name,_id:kset._id,hire_charge:hire_charge,added:lnt,status:status, isreturn: false});
        });

        kitWithKitSet.push({name:data[0].name,_id:data[0]._id,sets:subKitSet});
        this.setState({
            kitWithKitSet
        });
    }


    redirectPage(){
        this.setState({
            redirect:true
        });
        
    }


  

    componentDidMount(){
        //this.getSingleBooking(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
       
        if(nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if(nextProps.kitSetList !== undefined ) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if(nextProps.returnModal !== undefined && nextProps.returnModal==true && this.state.returnModal==false) {
            this.returnModalOpen(nextProps.bookingId);
        }

        if(nextProps.returnRefresh == true) {
            this.returnSuccess();
        }

    


      
             
    }

    render() {
        const { error, loading } = this.props;
       
       
        
        return (
            <div>

                <Modal size="md" isOpen={this.state.returnModal} toggle={() => this.returnModalClose()}>
                    <ModalHeader charcode="Y" toggle={() => this.returnModalClose()}>
                        Add Return
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group" style={{"display":"none"}}>
                                <label>Courier</label>
                                <Input 
                                    type="text" 
                                    className="form-control"
                                    value={this.state.returnData.courier}
                                    onChange={(e) => this.handleCourier(e.target.value)}
                                />
                                {this.state.returnSubmitFlag==1 && error  ? <span className="text-danger">{error.courier ? error.courier : ""}</span> : ""}

                            </div>
                            <div className="form-group">
                                <label>Select the returned Sets</label>
                                {this.state.kitWithKitSet && this.state.kitWithKitSet.map((kit,index)=>(
                                    <React.Fragment  key={index}>
                                        {kit.sets.map((setd, ind)=>(
                                            <React.Fragment  key={ind}>
                                                {setd.added==1 && setd.status!="close" && 
                                                    <div className="form-check" key={ind}>
                                                        {/* <Input 
                                                            type="radio" 
                                                            id={item._id}
                                                            name="hospitalAddress" 
                                                            value={item.name} 
                                                            checked={item.checked==true}
                                                            onChange={(e) => this.handleBookingAddress(e.target.value,e.target.checked,ind)}
                                                        /> */}
                                                        <Input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked = {setd.isreturn}
                                                            onChange={(e) => this.handleReturnSet(e.target.checked, index, ind)}
                                                        />
                                                        <label className="form-check-label" htmlFor="return_set">{setd.set_name}</label>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                                {(this.state.returnSubmitFlag==1 && this.state.returnSetDataLength==0) ? <span className="text-danger">Please check at least one item</span> : ""} 

                               
                            </div>
                            <label>Number of Boxes</label>
                            { this.state.boxArray.map((boxItem, index) => (            
                                (index == 0 ) ? 
                                    <div className="form-group row align-items-top no-gutters" key={index}>
                                        <div className="col-5 mb-3">              
                                            <Input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Box Type"
                                                onChange={(e) => this.handleReturnBoxType(index, e.target.value)}
                                                value={boxItem.box_type}
                                            />
                                            {boxItem.box_type=="" && this.state.returnSubmitFlag==1 ? <span className="text-danger">Please enter box type</span> : ""}
                                        </div>
                                        <div className="col-6 mb-3 px-3">              
                                            <Input 
                                                type="number" 
                                                className="form-control" 
                                                placeholder="Number of Boxes"
                                                min="0"
                                                onChange={(e) => this.handleReturnBoxNumber(index, e.target.value)}
                                                value={boxItem.number_of_box}
                                            />
                                            {boxItem.number_of_box=="" && this.state.returnSubmitFlag==1 ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                        </div>
                                        { index > 0 &&
                                            <div className="col-1 mb-3 text-center">
                                                <a className="ml-2" onClick={(e) => this.deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="form-group row align-items-center no-gutters" key={index}>
                                        <div className="col-5 mb-3">              
                                            <Input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Box Type"
                                                onChange={(e) => this.handleReturnBoxType(index, e.target.value)}
                                                value={boxItem.box_type}
                                            />
                                            {boxItem.box_type=="" && this.state.returnSubmitFlag==1 ? <span className="text-danger">Please enter box type</span> : ""}
                                        </div>
                                        <div className="col-6 mb-3 px-3">              
                                            <Input 
                                                type="number" 
                                                className="form-control" 
                                                placeholder="Number of Boxes"
                                                min="0"
                                                onChange={(e) => this.handleReturnBoxNumber(index, e.target.value)}
                                                value={boxItem.number_of_box}
                                            />
                                            {boxItem.number_of_box=="" && this.state.returnSubmitFlag==1 ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                        </div>
                                        { index > 0 &&
                                            <div className="col-1 mb-3 text-center">
                                                <a className="ml-2" onClick={(e) => this.deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                            </div>
                                        }
                                    </div>
                            ))}
                            <a href="#!" className="sml-btn" onClick={(e) => this.handleBoxAdd(e)}><i className="fa fa-plus"></i> Add More</a> 
                            { this.state.showDateInput &&
                                <div className="form-group mt-4">
                                    <label>Please specify the new return date in case of no return or partial return</label>
                                    <Input 
                                        type="date" 
                                        value={this.state.returnData.return_date}
                                        onChange = {(e) => this.handleReturnDate(e.target.value)}
                                        className="small"
                                    />
                                    {(this.state.returnSubmitFlag==1 && this.state.returnData.return_date=="") ? <span className="text-danger">Please pick date for next return</span> : ""} 
                                
                                    {this.state.returnSubmitFlag==1 && error ? <span className="text-danger">{error.return_date ? error.return_date : ""}</span> : ""}

                                </div>
                            }
                            <div className="form-group mt-4">
                                <label>Add a Note</label>          
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    value={this.state.returnData.additional_note}
                                    onChange={(e) => this.handleNote(e.target.value)}
                                ></textarea>
                                {this.state.returnSubmitFlag==1 && error ? <span className="text-danger">{error.additional_note ? error.additional_note : ""}</span> : ""}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary mr-2" onClick={this.returnModalClose}>Cancel</button>
                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length>0 && loading==false && 
                                <button className="btn btn-primary" onClick={this.submitReturnData}>Submit</button>
                            }

                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length>0 &&  loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Return Details Modal */}


            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingInfo,  error, kitSetList, redirect, returnRefresh, loading} = state.rootReducer.bookings;

    return {
        bookingInfo,
        kitSetList,
        error,
        redirect,
        returnRefresh,
        loading
      
    };
}

export default connect(mapStateToProps)(Return);